import { END_YEAR, START_YEAR } from '../constants/index';
import type { CombinedCompanyExperience, ExperienceInfo } from '../interface';
import { compareDates } from './dateUtils';

/**
 * Calculates the cumulative work experience date based on the combined experiences.
 *
 * @param combinedExperiences - An array of ExperienceInfo objects representing the combined work experiences.
 * @returns An object containing the cumulative start month, start year, end month, end year, and present status.
 */
const getCumulativeWorkExperienceDate = (combinedExperiences: ExperienceInfo[]) => {
  // Initialize the cumulative date with the first entry's values
  let cumulativeStartMonth = combinedExperiences[0].start_month;
  let cumulativeStartYear = combinedExperiences[0].start_year;
  let cumulativeEndMonth = combinedExperiences[0].end_month;
  let cumulativeEndYear = combinedExperiences[0].end_year;
  let cumulativePresent = combinedExperiences[0].present;

  combinedExperiences.forEach((entry) => {
    const startDateComparison = compareDates(
      new Date(cumulativeStartYear || END_YEAR, cumulativeStartMonth || 12),
      new Date(entry.start_year || END_YEAR, entry.start_month || 12),
    );

    // swap value if cumulative start date is greater than entry start date
    if (startDateComparison === 1) {
      cumulativeStartYear = entry.start_year;
      cumulativeStartMonth = entry.start_month;
    }

    const endDateComparison = compareDates(
      new Date(cumulativeEndYear || START_YEAR, cumulativeEndMonth || 0),
      new Date(entry.end_year || START_YEAR, entry.end_month || 0),
    );

    // swap value if cumulative end date is less than entry end date
    if (endDateComparison === -1) {
      cumulativeEndYear = entry.end_year;
      cumulativeEndMonth = entry.end_month;
    }

    cumulativePresent = cumulativePresent || entry.present;
  });

  return {
    start_month: cumulativeStartMonth,
    start_year: cumulativeStartYear,
    end_month: cumulativeEndMonth,
    end_year: cumulativeEndYear,
    present: cumulativePresent,
  };
};

// combine consecutive experiences with same company name and add a new field called combined_experiences
// else don't add combined experiences field if company name is different
export const getCombinedCompanyExperiences = (experiences: ExperienceInfo[] | undefined) => {
  if (!experiences) return [];

  return experiences?.reduce((acc, curr) => {
    const last = acc[acc.length - 1];

    if (
      last &&
      (last.group_name ? last.group_name === curr.group_name : last.company === curr.company)
    ) {
      last.combined_experiences.push(curr);
    } else {
      acc.push({ ...curr, combined_experiences: [curr] });
    }

    acc.forEach((item) => {
      const combinedDates = getCumulativeWorkExperienceDate(item.combined_experiences);
      Object.assign(item, combinedDates);
    });
    return acc;
  }, [] as CombinedCompanyExperience[]);
};

// function to  update index property in the experiences and combined_experiences and start from 0
export const resetIndexesInCompanies = (
  combinedCompanyExperiences: CombinedCompanyExperience[],
) => {
  if (!combinedCompanyExperiences) return [];

  let lastSetIndex = 0;
  combinedCompanyExperiences.forEach((companyExp) => {
    companyExp.index = lastSetIndex;
    companyExp.combined_experiences.forEach((exp) => {
      exp.index = lastSetIndex;
      lastSetIndex += 1;
    });
  });
  return combinedCompanyExperiences;
};

export const getHtmlStringFromEventStreamText = (data: string) => {
  // TODO: improve implementation, currently its only converting text to li tag

  // convert data string to HTML, add all text that start on a new line and has '- ' before text to a new li tag and the rest to a new p tag
  let htmlString = '';
  // let ulStarted = false;
  htmlString += '<ul class="editor-list-ul">';

  data
    .replace(/ \n/g, '')
    .split('- ')
    .forEach((line) => {
      // if (!line.startsWith('\n')) {
      //   if (!ulStarted) {
      //     htmlString += '<ul class="editor-list-ul">';
      //     ulStarted = true;
      //   }
      //   htmlString += `<li class="editor-listitem"><span style="white-space: pre-wrap;">${line.slice(
      //     2,
      //   )}</span></li>`;
      // } else {
      //   if (ulStarted) {
      //     htmlString += '</ul>';
      //     ulStarted = false;
      //   }
      //   htmlString += `<p class="editor-paragraph" dir="ltr"><span style="white-space: pre-wrap;">Text in p tag</span></p><p class="editor-paragraph">${line}</span></p>`;
      // }

      if (line !== '') {
        htmlString += `<li class="editor-listitem"><span style="white-space: pre-wrap;">${line}</span></li>`;
      }
    });

  htmlString += '</ul>';

  return htmlString.replace(/\n/g, ''); // removing new lines from BE response
};
