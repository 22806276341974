import { type ReactElement, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';

import { Box, styled } from '@mui/material';
import { useWindowSize } from '@uidotdev/usehooks';

import useIsTabletResponsivePage from '../../hooks/useIsTabletResponsivePage';
import useResponsiveDevice from '../../hooks/useResponsiveDevice';
import RoutePaths from '../../routes/RoutePaths';
import { useSideBarStore } from '../../stores/SideBarStore';
import { drawerWidth } from '../common/constants';
import AppBar from './components/AppBar';
import DesktopSideBar from './components/DesktopSideBar';
import MobileSideBar from './components/MobileSideBar';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
  isListingPage?: boolean;
  isMobile?: boolean;
}>(({ theme: themeConfigs, open, isListingPage, isMobile }) => ({
  maxWidth: '100%',
  boxSizing: 'border-box',
  flexGrow: 1,
  // eslint-disable-next-line no-nested-ternary
  padding: isMobile ? (isListingPage ? '0px' : '8px') : themeConfigs.spacing(isListingPage ? 0 : 3),
  // eslint-disable-next-line no-nested-ternary
  marginTop: isListingPage ? (isMobile ? '3.5rem' : '4rem') : '4.5rem', // TODO: rework this
  marginLeft: isMobile ? 0 : `-${drawerWidth - 150}px`,
  transition: themeConfigs.transitions.create('margin', {
    easing: themeConfigs.transitions.easing.sharp,
    duration: themeConfigs.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: themeConfigs.transitions.create('margin', {
      easing: themeConfigs.transitions.easing.easeOut,
      duration: themeConfigs.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const CommonLayout = ({
  children,
  key,
}: {
  key?: string;
  children?: ReactElement | ReactElement[];
}) => {
  const { isOpen, shouldHideAppBar, shouldHideSideBar } = useSideBarStore();
  const { isMobile, isTablet } = useResponsiveDevice();
  // TODO: quickfix for apply mobile sidebar and appabr to only listing pages
  const isTabletResponsivePage = useIsTabletResponsivePage();

  const location = useLocation();
  // TODO: update name this logic is not limited to listing pages only
  const isListingPage =
    location.pathname.includes(RoutePaths.JOBMATCHES) ||
    location.pathname.includes(RoutePaths.JOBS) ||
    (location.pathname.includes(RoutePaths.RESUMES) && !location.pathname.includes('edit')) ||
    location.pathname.endsWith(RoutePaths.HOME);

  const hiddenAppBarRoutes = useMemo(
    () => [RoutePaths.SIGN_IN, RoutePaths.SIGN_UP, RoutePaths.VERIFICATION, RoutePaths.ONBOARDING],
    [],
  );

  const hiddenSideBarRoutes = useMemo(
    () => [
      RoutePaths.SIGN_IN,
      RoutePaths.SIGN_UP,
      RoutePaths.VERIFICATION,
      RoutePaths.ONBOARDING,
      RoutePaths.SETTINGS,
    ],
    [],
  );

  const { hideAppBar, showAppBar, hideSideBar, showSideBar } = useSideBarStore();

  useLayoutEffect(() => {
    if (hiddenAppBarRoutes.some((path) => location.pathname.endsWith(path))) {
      hideAppBar();
    } else {
      showAppBar();
    }

    if (hiddenSideBarRoutes.some((path) => location.pathname.endsWith(path))) {
      hideSideBar();
    } else {
      showSideBar();
    }
  }, [
    hiddenAppBarRoutes,
    hiddenSideBarRoutes,
    hideAppBar,
    hideSideBar,
    location.pathname,
    showAppBar,
    showSideBar,
  ]);

  const shouldShowMobileLayout = isMobile || (isTablet && isTabletResponsivePage);
  const [sideDrawerWidth, setSideDrawerWidth] = useState(0);
  const { width } = useWindowSize();

  useEffect(() => {
    const drawer = document.querySelector('.desktop-drawer');

    if (drawer) {
      const resizeObserver = new ResizeObserver(() => {
        if (!shouldHideSideBar && !shouldShowMobileLayout) {
          setSideDrawerWidth(drawer.clientWidth);
        } else {
          setSideDrawerWidth(0);
        }
      });

      // Start observing the drawer element
      resizeObserver.observe(drawer);

      // Set the initial width
      setSideDrawerWidth(!shouldHideSideBar && !shouldShowMobileLayout ? drawer.clientWidth : 0);

      // Cleanup on component unmount
      return () => {
        resizeObserver.disconnect();
      };
    }
    // Return a no-op function if the drawer is not found
    return () => {};
  }, [shouldHideSideBar, shouldShowMobileLayout]);

  // mainContentWidth = windowWidth - sideDrawerWidth
  const mainContentWidth = useMemo(() => {
    if (width) {
      return width - sideDrawerWidth;
    }
    return 0;
  }, [sideDrawerWidth, width]);

  return (
    <Box key={key} sx={{ display: 'flex', overflow: 'hidden', minHeight: '100vh' }}>
      {!shouldHideAppBar && <AppBar />}
      {!shouldHideSideBar && (shouldShowMobileLayout ? <MobileSideBar /> : <DesktopSideBar />)}
      <Main
        open={isOpen}
        isListingPage={isListingPage}
        isMobile={shouldShowMobileLayout}
        sx={{ width: `${mainContentWidth}px` }}
      >
        {children}
      </Main>
    </Box>
  );
};

export default CommonLayout;
