import { useState } from 'react';

import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import FileDropzone from '../../components/FileDropzone';
import ProgressManager from '../../components/ProgressManager';
import { ProgressScreenType } from '../../components/ProgressManager/constants';
import BreadCrumbs from '../../components/common/BreadCrumbs';
import Button from '../../components/common/Button';
import Modal from '../../components/common/Modal/Modal';
import ModalActionsDeprecated from '../../components/common/Modal/ModalActions_deprecated';
import ModalDetail from '../../components/common/Modal/ModalDetail';
import { useSnackbar } from '../../contexts/snackbar';
import useIsMobile from '../../hooks/useIsMobile';
import RoutePaths from '../../routes/RoutePaths';
import { ResumesKeys, uploadResumeFile } from '../../services/resumes';
import { useSideBarStore } from '../../stores/SideBarStore';
import TextWrappedLinkButton from '../JobScore/ResumeScore/components/TextWrappedLinkButton';

const CreateResume = () => {
  const [resumeFile, setResumeFile] = useState<File>();
  const [resumeId, setResumeId] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const isMobile = useIsMobile();
  const { isOpen } = useSideBarStore();

  const { showSnackbar } = useSnackbar();
  const [isShowingProgress, setIsShowingProgress] = useState(false);

  const BreadCrumbLinks = [
    {
      label: 'Resumes',
      href: RoutePaths.RESUMES,
    },
    {
      label: 'Import',
      href: RoutePaths.CREATE_RESUME,
    },
  ];

  // query client & mutations
  const queryClient = useQueryClient();

  const { isLoading: isResumeUploading, mutate: uploadResume } = useMutation({
    retry: 3,
    mutationFn: uploadResumeFile,
    onSuccess: (data) => {
      queryClient.invalidateQueries([ResumesKeys.RESUMES]); // invalidate, so that updated resumes are fetched
      setResumeId(data?._id);
      setIsShowingProgress(true);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      showSnackbar(
        'error',
        error.response?.data?.errors || 'Failed to upload Resume. Please try again.',
      );
    },
  });

  const handleSelectFile = (file: File | undefined) => {
    setResumeFile(file);
  };

  const onClickNext = () => {
    if (!resumeFile) {
      throw new Error('Resume file is not selected');
    }

    uploadResume(resumeFile as File);
  };

  return (
    // quickfix: removing left margin added by layout component
    <Stack
      sx={{
        marginLeft: isShowingProgress && !isOpen && !isMobile ? '20px' : '0px',
      }}
    >
      {/* create resume page */}
      <Stack
        sx={{
          height: '100%',
          width: isShowingProgress ? `calc(100% - ${isMobile ? '24px' : '48px'})` : '80vw',
          maxWidth: '1920px',
          margin: '32px auto',
        }}
      >
        {!isShowingProgress ? (
          <>
            <BreadCrumbs links={BreadCrumbLinks} />

            <Stack
              sx={{
                marginTop: 3,
                paddingLeft: '20px',
              }}
            >
              <FileDropzone
                label="Resume Upload"
                helperText={
                  <TextWrappedLinkButton
                    variant="body3"
                    text={{
                      startingText: 'Importing a new resume creates a base resume. ',
                      linkText: 'What is a base resume?',
                      endingText: '',
                    }}
                    onClick={() => setShowModal(true)}
                  />
                }
                onFileChange={handleSelectFile}
              />
              <Modal open={showModal} contentSx={{ width: '500px' }}>
                <>
                  <ModalDetail
                    title="What is a Base Resume?"
                    description="Importing a new resume creates a base resume that you can duplicate, edit, AI customize, and download. Using the base resume, you can easily create many versions of resumes for different job positions. Avoid importing the same resume twice."
                  />
                  <ModalActionsDeprecated
                    actions={
                      <Button color="primary" onClick={() => setShowModal(false)}>
                        <Typography variant="label2">Got it</Typography>
                      </Button>
                    }
                  />
                </>
              </Modal>
            </Stack>

            <Stack sx={{ marginTop: '32px', alignItems: 'flex-end' }}>
              <Button
                onClick={onClickNext}
                disabled={isResumeUploading || !resumeFile}
                loading={isResumeUploading}
              >
                Next
              </Button>
            </Stack>
          </>
        ) : (
          <ProgressManager
            id={resumeId || ''}
            progressScreenKey={ProgressScreenType.AiResumeScan}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default CreateResume;
