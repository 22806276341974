import { isEmpty } from 'ramda';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Stack, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import { monthList, yearsList } from '../../../constants';
import type { AchievementInfo } from '../../../interface';
import {
  getIntegerMonth,
  getStringMonth,
  isValidMonth,
  validateIssuedYear,
} from '../../../utils/dateUtils';
import FormActions from '../../Form/FormActions';
import RichTextEditor from '../../RichTextEditor/RichTextEditor';
import Dropdown from '../../common/Dropdown';
import InputLabel from '../../common/InputLabel';

interface AchievementEditorProps {
  // Define a prop to accept the callback function
  onUpdate: (achievement: AchievementInfo, method: string) => void;
  defaultAchievement?: AchievementInfo;
  resumeId?: string;
}

const AchievementEditor = ({ onUpdate, defaultAchievement }: AchievementEditorProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm();

  const [description, setDescription] = useState('');
  const method = defaultAchievement?._id === '' ? 'add' : 'edit';

  // Define a function to handle the form submission
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (data: any) => {
    onUpdate(
      {
        ...defaultAchievement,
        ...data,
        issued_year: data.issued_year === '' ? null : data.issued_year,
        issued_month:
          isValidMonth(data.issued_month) || isEmpty(data.issued_month)
            ? getIntegerMonth(data.issued_month)
            : defaultAchievement?.issued_month,
        description,
      },
      method,
    );
  };

  // TODO: Rework on setting default values for the inputs
  useEffect(() => {
    setValue('organization', defaultAchievement?.organization || '', { shouldValidate: false });
    setValue('name', defaultAchievement?.name);
    setValue('issued_month', getStringMonth(defaultAchievement?.issued_month));
    setValue('issued_year', defaultAchievement?.issued_year);
    setDescription(defaultAchievement?.description || '');
  }, [setValue, defaultAchievement]);

  const onValidateIssuedYear = (issuedYear: number) => {
    if (!validateIssuedYear(getValues('issued_month'), issuedYear)) {
      return 'Issued year is required';
    }
    return true;
  };

  const onRichTextEditorChange = (text: string) => {
    setDescription(text);
  };

  const onSaveClick = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Stack gap={3}>
        <Stack gap={1}>
          <InputLabel htmlFor="name" label="Recognition Name" required />
          <Typography variant="subtitle2" sx={{ color: 'text.secondary', marginTop: -1 }}>
            Enter your award, honor or recognition. This is your achievement recognized by others.
          </Typography>
          <TextField
            id="name"
            placeholder="Sales person of the year award"
            {...register('name', { required: 'Enter a recognition name' })}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid',
                  borderColor: 'border.light',
                  borderRadius: '8px',
                  padding: '10px',
                },
              },
            }}
            inputProps={{
              maxLength: 64,
            }}
            error={!!errors.name}
            variant="outlined"
            fullWidth
          />
          {!!errors?.name && (
            <Typography variant="assistive" color="error">
              {errors?.name?.message?.toString()}
            </Typography>
          )}
        </Stack>
        <Stack gap={1}>
          <InputLabel htmlFor="organization" label="Recognized by" />
          <TextField
            placeholder="Ford Motor Co."
            {...register('organization')}
            sx={{
              '& .MuiOutlinedInput-root': {
                border: '1px solid',
                borderColor: 'border.light',
                borderRadius: '8px',
                padding: 0,
                '& .MuiInputBase-input': {
                  padding: '10px',
                },
                '& fieldset': {
                  border: 0,
                },
              },
            }}
            variant="outlined"
            fullWidth
          />
        </Stack>
        <Stack gap={1}>
          <InputLabel label="Issued Date" />
          <Grid2 justifyContent="space-between" spacing={1} container>
            <Grid2 xs={6}>
              <Dropdown
                {...register('issued_month')}
                items={monthList}
                placeholder="Select Issued Month"
                defaultValue={getStringMonth(defaultAchievement?.issued_month)}
                control={control}
              />
            </Grid2>
            <Grid2 xs={6}>
              <Dropdown
                {...register('issued_year', { validate: onValidateIssuedYear })}
                items={yearsList}
                placeholder="Select Issued Year"
                defaultValue={defaultAchievement?.issued_year}
                control={control}
              />
            </Grid2>
          </Grid2>
        </Stack>
        <Stack gap={1}>
          <InputLabel label="Description" />
          <Typography variant="subtitle2" sx={{ color: 'text.secondary', marginTop: -1 }}>
            Describe details of the award
          </Typography>
          {/* TODO: limit description max characters to 128*/}
          {/* Rich text editor */}
          <RichTextEditor
            placeholder="In recognition of achieving 200% of annual target"
            html={defaultAchievement?.description || ''}
            onChange={onRichTextEditorChange}
          />
        </Stack>
        <FormActions sx={{ marginTop: '24px' }} onSubmit={onSaveClick} />
      </Stack>
    </form>
  );
};

export default AchievementEditor;
