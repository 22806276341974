import { useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Stack, Typography, useTheme } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import Grid from '@mui/system/Unstable_Grid';
import type { GridColDef } from '@mui/x-data-grid';

import { DataGridWithEmptyState } from '../../../components/DataGrid';
import { ColumnCell } from '../../../components/DataGrid/ColumnCell';
import Button from '../../../components/common/Button';
import Icon from '../../../components/common/Icon';
import ProgressStatus from '../../../components/common/ProgressStatus';
import useWindowResize from '../../../hooks/useWindowResize';
import type { ResponseStatus } from '../../../interface';
import RoutePaths from '../../../routes/RoutePaths';
import type { JobMatchScore } from '../../../services/jobMatches';
import type { ScoreColumnProps } from '../../../utils';
import { sortScoreColumn } from '../../../utils';
import { compareDates, dateToTimeAgo } from '../../../utils/dateUtils';
import CreateNewListing from '../../Create/CreateNewListing';
import { Controls } from './Controls';
import ScoreCircleCell from './ScoreCircleCell';

interface JobMatchesTableProps {
  jobMatches: JobMatchScore[] | undefined;
  isLoading?: boolean;
  onClick?: (id: string, rowStatus: ResponseStatus) => void;
}

const JobMatchesTable = ({ jobMatches, isLoading, onClick }: JobMatchesTableProps) => {
  const navigate = useNavigate();
  const theme: Theme = useTheme();
  const [contentHeight, setContentHeight] = useState(0);
  const [contentHeaderHeight, setContentHeaderHeight] = useState(0);
  const headerRef = useRef<HTMLDivElement>(null);

  const handleResize = () => {
    const appBarHeight = document.querySelector('.MuiToolbar-root')?.clientHeight || 0;
    const fullHeight = window.innerHeight;
    setContentHeight(fullHeight - appBarHeight);
    setContentHeaderHeight(headerRef.current?.clientHeight || 0);
  };

  useWindowResize(handleResize);

  const onRenderScore = useCallback(({ progress, score, status }: ScoreColumnProps) => {
    const onSuccess = <ScoreCircleCell score={score} />;
    return <ProgressStatus progress={progress} status={status} onSuccess={onSuccess} />;
  }, []);

  const tableHeaders: GridColDef[] = useMemo(
    () => [
      {
        field: 'job',
        headerName: 'Job X-Ray',
        flex: 1,
        renderCell: (params) => ColumnCell(params.value),
        sortComparator: (value1, value2) => value1.primaryText.localeCompare(value2.primaryText),
        disableColumnMenu: true,
      },
      {
        field: 'resume',
        headerName: 'Resume',
        flex: 1,
        renderCell: (params) => ColumnCell(params.value),
        sortComparator: (value1, value2) => value1.primaryText.localeCompare(value2.primaryText),
        disableColumnMenu: true,
      },
      {
        field: 'score',
        headerName: 'Score',
        flex: 0.8,
        renderCell: (params) => onRenderScore(params.value),
        sortComparator: (value1, value2) => sortScoreColumn(value1, value2),
        disableColumnMenu: true,
      },
      {
        field: 'lastUpdated',
        headerName: 'Created',
        flex: 1,
        disableColumnMenu: true,
        renderCell: (params) =>
          params.value.status === 'in_progress'
            ? 'In Progress'
            : dateToTimeAgo(params.value.updated_at),
        sortComparator: (value1, value2) => compareDates(value1.updated_at, value2.updated_at),
      },
      {
        field: 'controls',
        headerName: '',
        flex: 0.3,
        renderCell: (params) => Controls(params.value),
        disableColumnMenu: true,
        sortable: false,
      },
    ],
    [onRenderScore],
  );

  const DataGridRows = useMemo(() => {
    const rows = jobMatches?.map((jobMatch) => ({
      id: jobMatch._id,
      job: {
        primaryText: jobMatch.job_company,
        secondaryText: jobMatch.job_title,
      },
      resume: {
        // TODO: revisit primary text
        primaryText: jobMatch.resume_is_ai_customized
          ? jobMatch?.resume_name || jobMatch?.job_company || ''
          : 'Base',
        secondaryText: jobMatch.resume_filename,
        isAiCustomized: jobMatch.resume_is_ai_customized,
      },
      score: {
        progress: jobMatch.progress,
        score: jobMatch.total_score,
        status: jobMatch.status,
      },
      lastUpdated: { status: jobMatch.status, updated_at: jobMatch.updated_at },
      controls: {
        id: jobMatch._id,
        jobCompany: jobMatch.job_company,
        jobTitle: jobMatch.job_title,
      },
    }));

    return rows || [];
  }, [jobMatches]);

  const onDataGridRowClick = (params: {
    row: { id: string; score: { status: ResponseStatus } };
  }) => {
    const rowId = params.row.id;
    const rowStatus = params.row.score.status;
    if (onClick) {
      onClick(rowId, rowStatus);
    }
  };

  const CreateJobMatchButton = (
    <Button sx={{ paddingRight: '15px' }} onClick={() => navigate(RoutePaths.CREATE_JOB_MATCH)}>
      <Icon className="fi fi-rr-plus" style={{ fontSize: '13px', padding: '3.5px' }} />
      Create
    </Button>
  );

  return (
    <Grid
      container
      sx={{
        justifyContent: 'center',
        backgroundColor: 'background.mediumLight',
        height: contentHeight,
        maxHeight: contentHeight,
        padding: '32px 32px 0px 32px',
      }}
    >
      <Grid xs={11.5}>
        <Stack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '1rem',
            paddingBottom: 4,
          }}
          ref={headerRef}
        >
          <Stack>
            <Typography variant="h3">Job Matches</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Job matches create a score that indicates how well your Resume matches a Job
            </Typography>
          </Stack>
          {DataGridRows.length > 0 && CreateJobMatchButton}
        </Stack>
        {/* job matches table */}
        {/* TODO: Create a styled component for datagrid */}
        <DataGridWithEmptyState
          getRowHeight={() => 'auto'}
          hideFooterPagination
          columns={tableHeaders}
          rows={DataGridRows}
          isLoading={isLoading}
          onRowClick={onDataGridRowClick}
          sortingOrder={['asc', 'desc']}
          emptyGridState={
            <CreateNewListing
              primaryText="Create your first Job Match"
              secondaryText="Get your job match score and find out how well your resume matches a job description."
              icon="fi fi-br-exchange"
              action={CreateJobMatchButton}
            />
          }
          mainBoxSx={{
            flexGrow: DataGridRows.length > 0 ? 0 : 1,
            height: `calc(${contentHeight}px - ${contentHeaderHeight}px - ${theme.spacing(
              DataGridRows.length > 0 ? 4 : 9,
            )})`,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default JobMatchesTable;
