import { CircularProgress, Button as MUIButton, Typography } from '@mui/material';
import type { ButtonProps as MUIButtonProps } from '@mui/material';

export interface ButtonProps extends MUIButtonProps {
  loading?: boolean;
  trailingIcon?: React.ReactNode;
  startIcon?: React.ReactNode;
  disabled?: boolean;
}

const Button = ({
  variant = 'contained',
  loading,
  children,
  disabled = false,
  sx,
  startIcon,
  trailingIcon,
  ...restProps
}: ButtonProps) => (
  <MUIButton
    disabled={disabled || loading}
    variant={variant}
    sx={{
      padding: variant === 'outlined' ? '7px 12px' : '8px 12px',
      minWidth: '80px',
      textTransform: 'capitalize',
      color: variant !== 'contained' ? 'primary.main' : 'common.white', // TODO: rethink, we might beed common.black by default
      borderRadius: '8px',
      borderWidth: variant === 'outlined' ? '2px' : '0px',
      ':hover': {
        borderWidth: variant === 'outlined' ? '2px' : '0px',
      },
      ...sx,
    }}
    {...restProps}
  >
    <Typography sx={{ display: 'flex', alignItems: 'center', gap: '8px' }} variant="label2">
      {startIcon && startIcon}
      {loading && <CircularProgress sx={{ color: 'inherit' }} size={20} />}
      {children}
      {trailingIcon && trailingIcon}
    </Typography>
  </MUIButton>
);

export default Button;
