// TODO: Interfaces should be colocated with the component that originated them.

// TODO: rethink the name and standardize across app (there is a lot of overlap with ResumeSectionKeys)
// Enum for resume keys
export enum ResumeKey {
  RESUME_ID = 'id',
  FILENAME = 'filename',
  CONTACT = 'contact',
  HERO = 'hero_message',
  OBJECTIVE = 'objective',
  ACHIEVEMENTS = 'achievements',
  CERTIFICATES = 'certifications_and_licenses',
  VOLUNTEER_EXPERIENCE = 'volunteer_experience',
  SUMMARY = 'professional_summary',
  SKILLS = 'skills',
  EXPERIENCE = 'work_experience',
  COURSEWORK = 'coursework',
  INTERESTS = 'interests',
  EDUCATION = 'education',
  PROJECT = 'project',
  PATENTS = 'patents',
  PUBLICATIONS = 'publications',
  SECTIONS_ORDER = 'section_order',
}

export enum ResponseStatus {
  IN_PROGRESS = 'in_progress',
  DONE = 'done',
  ERROR = 'error',
  TODO = 'todo',
  MISSING = 'missing',
  PENDING = 'pending',
}

export interface ResumeEditorState {
  action: string;
  resumeId: string;
  elementId: string;
  elementType: string;
  redirectpath?: string;
}

// TODO: verify and remove
export interface JdResumeId {
  job_description_id: string;
  resume_id: string;
}

export interface SummaryInfo {
  _id?: string;
  professional_summary: string;
}

export interface ContactInfo {
  _id: string;
  first_name: string;
  last_name: string;
  city: string;
  state: string;
  country: string;
  display_country: boolean;
  phone: string;
  email: string;
  linkedin: string;
}

export interface HeroInfo {
  _id: string;
  message: string;
}

export interface ObjectiveInfo {
  _id: string;
  subtext: string;
}

// TODO: Date should be a timestamp.
export interface ExperienceInfo {
  _id: string;
  job_title: string;
  company: string;
  start_month: number | null;
  start_year: number | null;
  end_month: number | null;
  end_year: number | null;
  present: boolean;
  location: string;
  description?: string;
  combined_experiences?: ExperienceInfo[];
  index?: number;
  group_name?: string;
  is_career_break?: boolean;
}

export interface EducationInfo {
  _id: string;
  degree: string;
  start_month: number | null;
  start_year: number | null;
  end_month: number | null;
  end_year: number | null;
  school: string;
  gpa: number | null;
  total_gpa: number | null;
  major: string;
  present: boolean;
  index: number;
}

export interface ProjectInfo {
  _id: string;
  project_name: string;
  organization: string;
  description?: string;
  start_month: number | null;
  start_year: number | null;
  end_month: number | null;
  end_year: number | null;
  present: boolean;
  index: number;
}

export interface SectionOrder {
  section_name: string;
  name: string;
  enabled: boolean;
}

export interface CombinedCompanyExperience extends ExperienceInfo {
  combined_experiences: ExperienceInfo[];
}

export interface SortedResumeSectionKey {
  id: string;
  index: number | undefined;
}

export interface SkillsInfo {
  _id: string;
  skills: string[];
}

export interface CourseworkInfo {
  _id: string;
  coursework: string[];
}

export interface InterestsInfo {
  _id: string;
  interests: string[];
}

export interface PatentInfo {
  _id: string;
  title: string;
  number: string;
}

export interface PublicationInfo {
  _id: string;
  title: string;
  publisher: string;
  issued_month: number | null;
  issued_year: number | null;
}

export interface VolunteerExperienceInfo {
  _id: string;
  organization: string;
  location: string;
  start_month: number | null;
  start_year: number | null;
  end_month: number | null;
  end_year: number | null;
  present: boolean;
  description?: string;
}

export interface AchievementInfo {
  _id: string;
  name: string;
  organization: string;
  issued_month: number | null;
  issued_year: number | null;
  description: string;
}
export interface CertificatesLicensesInfo {
  _id: string;
  name: string;
  organization: string;
  issued_month: number | null;
  issued_year: number | null;
}

export enum TemplateDescriptionType {
  PRO = 'pro',
  CON = 'con',
}

export interface TemplateDescription {
  type: TemplateDescriptionType;
  text: string;
}

export interface ResumeTemplateInfo {
  _id: string;
  key: ResumeTemplateKey;
  name: string;
  ats_score: number;
  descriptions: TemplateDescription[];
  thumbnail_url: string;
  enabled: boolean;
}

export interface SelectedResumeTemplateInfo {
  selected_template_key: string;
}

export enum ResumeTemplateKey {
  StandardLayoutA = 'standard_layout_a',
  StandardLayoutB = 'standard_layout_b',
  ModernLayoutA = 'modern_layout_a',
  ModernLayoutB = 'modern_layout_b',
}

// export an type that combines all the section Info interfaces
export type ResumeSectionType =
  | SummaryInfo
  | ContactInfo
  | HeroInfo
  | ObjectiveInfo
  | ExperienceInfo
  | AchievementInfo
  | ProjectInfo
  | EducationInfo
  | SkillsInfo
  | CourseworkInfo
  | InterestsInfo
  | PatentInfo
  | PublicationInfo
  | VolunteerExperienceInfo
  | CertificatesLicensesInfo
  | SelectedResumeTemplateInfo;

export interface ResumeData {
  id: string;
  name: string;
  is_ai_customized: boolean;
  filename: string;
  selected_template: ResumeTemplateInfo;
  career_stage: string;
  // TODO: discuss with backend team and change the key name to selected_template_id.
  selected_template_key: string;
  contact: ContactInfo;
  hero_message: HeroInfo;
  objective: ObjectiveInfo;
  professional_summary: SummaryInfo;
  achievements: AchievementInfo[];
  skills: SkillsInfo;
  work_experience: ExperienceInfo[];
  project: ProjectInfo[];
  education: EducationInfo[];
  coursework: CourseworkInfo;
  certifications_and_licenses: CertificatesLicensesInfo[];
  interests: InterestsInfo;
  patents: PatentInfo[];
  publications: PublicationInfo[];
  volunteer_experience: VolunteerExperienceInfo[];
  section_order: SectionOrder[];
}
