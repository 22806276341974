// TODO: implement consistent naming convention/spellings for routes
enum RoutePaths {
  HOME = '/',
  SIGN_IN = '/signin',
  SIGN_UP = '/signup',
  VERIFICATION = '/verification',
  ONBOARDING = '/onboarding',
  RESUMES = '/resumes',
  JOBMATCHES = '/job-matches',
  JOBMATCH = '/job-match',
  JOB_SCORE = '/job-matches/:id',
  JOBS = '/jobs',
  JOB_XRAY = '/jobs/:jobId',
  RESUME_HANDLER = '/resumes/:id',
  RESUME_EDIT = '/resumes/edit/:key',
  RESUME_REORDER = '/resumes/reorder/:key', // TODO: use nested routes instead
  RESUME_SECTION_WORK_EXPERIENCE = '/resumes/reorder/work-experience',
  CREATE_JOB_MATCH = '/create/job-matches',
  CREATE_RESUME = '/create/resumes',
  CREATE_JOB = '/create/jobs',
  AI_CUSTOMIZED = '/resumes/ai-customized/:id',
  SETTINGS = '/settings',
}

export default RoutePaths;
