import type { ChangeEvent } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { FormHelperText, InputLabel, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import ProgressManager from '../../components/ProgressManager';
import { ProgressScreenType } from '../../components/ProgressManager/constants';
import BreadCrumbs from '../../components/common/BreadCrumbs';
import Button from '../../components/common/Button';
import Modal from '../../components/common/Modal/Modal';
import ModalActionsDeprecated from '../../components/common/Modal/ModalActions_deprecated';
import ModalDetail from '../../components/common/Modal/ModalDetail';
import TextField from '../../components/common/TextField';
import { useSnackbar } from '../../contexts/snackbar';
import useIsMobile from '../../hooks/useIsMobile';
import RoutePaths from '../../routes/RoutePaths';
import { JobDescriptionTypes, uploadJobDescription } from '../../services/jobDescriptions';
import { useSideBarStore } from '../../stores/SideBarStore';
import { isNotNilOrEmpty } from '../../utils';
import TextWrappedLinkButton from '../JobScore/ResumeScore/components/TextWrappedLinkButton';
import JobDescriptionExample from './JobDescriptionExample';

const CreateJob = () => {
  const [importJDText, setImportJDText] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const isMobile = useIsMobile();
  const { isOpen } = useSideBarStore();
  const location = useLocation();
  const [jobDescriptionId, setJobDescriptionId] = useState<string>(
    location?.state?.jobDescriptionId || '',
  );

  const { showSnackbar } = useSnackbar();
  const [isShowingProgress, setIsShowingProgress] = useState(
    isNotNilOrEmpty(location?.state?.jobDescriptionId),
  );

  const BreadCrumbLinks = [
    {
      label: 'Jobs',
      href: RoutePaths.JOBS,
    },
    {
      label: 'Add',
      href: RoutePaths.CREATE_JOB,
    },
  ];

  // query client & mutations
  const queryClient = useQueryClient();

  const { isLoading: isJdUploading, mutate: uploadJD } = useMutation({
    retry: 3,
    mutationFn: uploadJobDescription,
    onSuccess: (jdUploadData) => {
      queryClient.invalidateQueries([JobDescriptionTypes.JOBXRAYS]); // invalidate, so that updated resumes are fetched
      setJobDescriptionId(jdUploadData._id);
      setIsShowingProgress(true);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      showSnackbar(
        'error',
        error.response?.data?.errors || 'Failed to upload Job Description. Please try again.',
      );
    },
  });

  const handleJDImportTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    setImportJDText(event.target.value);
  };

  const onClickNext = () => {
    if (!importJDText) {
      throw new Error('Job Description cannot be empty');
    }

    uploadJD(importJDText);
  };

  return (
    // quickfix: removing left margin added by layout component
    <Stack
      sx={{
        marginLeft: isShowingProgress && !isOpen && !isMobile ? '20px' : '0px',
      }}
    >
      {/* create resume page */}
      <Stack
        sx={{
          height: '100%',
          width: isShowingProgress ? `calc(100% - ${isMobile ? '24px' : '48px'})` : '80vw',
          maxWidth: '1920px',
          margin: '32px auto',
        }}
      >
        {!isShowingProgress ? (
          <>
            <Stack gap="4px">
              <BreadCrumbs links={BreadCrumbLinks} />
              <Typography variant="body2" sx={{ paddingLeft: '30px' }}>
                Create a Job X-Ray that highlights requirements of the job.
              </Typography>
            </Stack>
            <Stack
              sx={{
                marginTop: 3,
                paddingLeft: '20px',
              }}
            >
              {/* TODO: Create a separate component for import a job */}
              <Stack paddingLeft="10px">
                <InputLabel>
                  <Typography variant="label2" sx={{ color: 'text.primary' }}>
                    Import a Job
                  </Typography>
                </InputLabel>
                <FormHelperText sx={{ marginTop: '0px', marginBottom: '8px' }}>
                  <TextWrappedLinkButton
                    variant="body3"
                    text={{
                      startingText:
                        'Find a job posting you’re interested in and provide the job description. ',
                      linkText: 'See example',
                      endingText: '',
                    }}
                    onClick={() => setShowModal(true)}
                  />
                </FormHelperText>
                <Modal open={showModal} contentSx={{ width: '50vw' }}>
                  <>
                    <ModalDetail
                      title="Example Job Posting from LinkedIn"
                      description={<JobDescriptionExample />}
                    />
                    <ModalActionsDeprecated
                      actions={
                        <Button color="primary" onClick={() => setShowModal(false)}>
                          <Typography variant="label2">Close</Typography>
                        </Button>
                      }
                    />
                  </>
                </Modal>
                {/* TODO: Update Input field padding */}
                <TextField
                  placeholder="Copy and paste the entire job description from the job posting, including the company name and job title. You do NOT have to clean up the text!"
                  type="textarea"
                  multiline
                  minRows={10}
                  maxRows={20}
                  sx={{
                    border: '1px solid',
                    borderColor: 'border.light',
                    boxShadow: 'none',
                    '& .MuiInputBase-root': {
                      padding: 0,
                    },
                  }}
                  value={importJDText}
                  onChange={handleJDImportTextFieldChange}
                />
              </Stack>
            </Stack>

            <Stack sx={{ marginTop: '32px', alignItems: 'flex-end' }}>
              <Button
                onClick={onClickNext}
                disabled={isJdUploading || !importJDText}
                loading={isJdUploading}
              >
                Next
              </Button>
            </Stack>
          </>
        ) : (
          <ProgressManager id={jobDescriptionId} progressScreenKey={ProgressScreenType.JDXray} />
        )}
      </Stack>
    </Stack>
  );
};

export default CreateJob;
