import type { ChangeEvent } from 'react';
import { useState } from 'react';

import {
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import Button from '../../../components/common/Button';
import Card from '../../../components/common/Card';
import Modal from '../../../components/common/Modal/Modal';
import ModalActionsDeprecated from '../../../components/common/Modal/ModalActions_deprecated';
import ModalDetail from '../../../components/common/Modal/ModalDetail';
import TextField from '../../../components/common/TextField';
import { ResponseStatus } from '../../../interface';
import { JobDescriptionTypes, getAllJobXRays } from '../../../services/jobDescriptions';
import TextWrappedLinkButton from '../../JobScore/ResumeScore/components/TextWrappedLinkButton';
import JobDescriptionExample from '../JobDescriptionExample';

export enum SelectJDSection {
  SELECT_JD = 'select-jd',
  IMPORT_JD = 'import-jd',
}

const SelectJobDescription = ({
  onSelected,
  defaultValue,
}: {
  onSelected: (data: {
    jdId?: string;
    resumeId?: string;
    jDText?: string;
    resumeFile?: File;
  }) => void;
  defaultValue?: {
    section: SelectJDSection;
    value: string;
  };
}) => {
  const [selectedSection, setSelectedSection] = useState<SelectJDSection>(
    defaultValue ? defaultValue.section : SelectJDSection.SELECT_JD,
  );

  const [showModal, setShowModal] = useState<boolean>(false);
  const [jobDescriptionId, setJobDescriptionId] = useState<string>();
  const [importJDText, setImportJDText] = useState<string>(
    defaultValue?.section === SelectJDSection.IMPORT_JD ? defaultValue?.value : '',
  );

  const { data: jDQueryResponse, isLoading } = useQuery({
    queryKey: [JobDescriptionTypes.JOBXRAYS],
    queryFn: () => getAllJobXRays(ResponseStatus.DONE),
  });

  const jobDescriptions = jDQueryResponse?.job_description_list;

  const handleSelectSelectionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedSection(event.target.value as SelectJDSection);
  };

  const handleSelectJD = (event: ChangeEvent<HTMLInputElement>) => {
    onSelected({ jdId: event.target.value });
    setJobDescriptionId(event.target.value);
  };

  const handleJDImportTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    setImportJDText(event.target.value);
    onSelected({ jDText: event.target.value });
  };

  return (
    <Stack sx={{ marginTop: 4 }}>
      <FormControl>
        <RadioGroup
          defaultValue={selectedSection}
          name="select-jd-sections-group"
          onChange={handleSelectSelectionChange}
        >
          <FormControlLabel
            value={SelectJDSection.SELECT_JD}
            control={<Radio />}
            label={
              <Typography
                variant="body2"
                sx={{
                  fontWeight: selectedSection === SelectJDSection.SELECT_JD ? '500' : 'inherit',
                }}
              >
                Select a Job X-Ray
              </Typography>
            }
          />
          {/* Select Jd section goes here */}
          {selectedSection === SelectJDSection.SELECT_JD && (
            // TODO: move to a separate component
            <Stack sx={{ marginBottom: '40px', paddingLeft: '20px' }}>
              <FormHelperText sx={{ margin: '0px 0px 8px 10px' }}>
                <Typography variant="body3">
                  A Job X-Ray is used by AI to customize your resume as well as to calculate your
                  job match score.
                </Typography>
              </FormHelperText>
              <Card
                rootSx={{
                  '& .MuiCardContent-root': {
                    paddingLeft: 0,
                    paddingRight: 0,
                  },
                }}
                sx={{
                  borderRadius: '8px',
                  height: '40vh',
                  overflowY: 'scroll',
                }}
              >
                <FormControl sx={{ width: '100%' }}>
                  <Stack alignItems="center">{isLoading && <CircularProgress />}</Stack>

                  <RadioGroup
                    name="select-job-descriptions-group"
                    onChange={handleSelectJD}
                    defaultValue={defaultValue?.value}
                  >
                    {jobDescriptions?.map((jobDescription) => (
                      <FormControlLabel
                        key={jobDescription._id}
                        value={jobDescription._id}
                        control={<Radio />}
                        label={
                          <Stack
                            sx={{
                              flexDirection: 'row',
                              alignItems: 'center',
                              gap: '4px',
                            }}
                          >
                            <Chip
                              label={
                                <Typography
                                  variant={
                                    jobDescriptionId === jobDescription._id
                                      ? 'assistiveBold'
                                      : 'assistive'
                                  }
                                >
                                  {jobDescription.job_company || '-'}
                                </Typography>
                              }
                              sx={{
                                backgroundColor:
                                  jobDescriptionId === jobDescription._id
                                    ? 'primary.mediumLight'
                                    : 'background.main',
                                color:
                                  jobDescriptionId === jobDescription._id
                                    ? 'primary.mediumDark'
                                    : 'inherit',
                                borderRadius: 4,
                                height: '24px',
                                '& .MuiChip-label': {
                                  padding: '0px 8px',
                                },
                              }}
                            />
                            <Typography variant="body3">
                              {jobDescription.job_title || '-'}
                            </Typography>
                          </Stack>
                        }
                        sx={{
                          marginLeft: 0,
                          marginRight: 0,
                          borderRadius: 1,
                          padding: '16px 12px',
                          height: '20px',
                          ':hover': {
                            backgroundColor: '#EBEBEB',
                          },
                          backgroundColor:
                            jobDescriptionId === jobDescription._id
                              ? 'primary.light'
                              : 'transparent',
                        }}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Card>
            </Stack>
          )}
          <FormControlLabel
            value={SelectJDSection.IMPORT_JD}
            control={<Radio />}
            label={<Typography variant="body2">Import a job</Typography>}
          />
          {/* Import JD goes here */}
          {selectedSection === SelectJDSection.IMPORT_JD && (
            <Stack paddingLeft="18px">
              <FormHelperText sx={{ marginTop: '0px', marginBottom: '8px' }}>
                <Typography variant="body3">
                  <TextWrappedLinkButton
                    variant="body3"
                    text={{
                      startingText:
                        'Find a job posting you’re interested in and provide the job description. ',
                      linkText: 'See example',
                      endingText: '',
                    }}
                    onClick={() => setShowModal(true)}
                  />
                </Typography>
              </FormHelperText>
              <Modal open={showModal} contentSx={{ width: '50vw' }}>
                <>
                  <ModalDetail
                    title="Example Job Posting from LinkedIn"
                    description={<JobDescriptionExample />}
                  />
                  <ModalActionsDeprecated
                    actions={
                      <Button color="primary" onClick={() => setShowModal(false)}>
                        <Typography variant="label2">Close</Typography>
                      </Button>
                    }
                  />
                </>
              </Modal>
              {/* TODO: Update Input field padding */}
              <TextField
                placeholder="Copy and paste the entire job description from the job posting, including the company name and job title. You do NOT have to clean up the text!"
                type="textarea"
                multiline
                minRows={10}
                maxRows={20}
                sx={{
                  border: '1px solid',
                  borderColor: 'border.light',
                  boxShadow: 'none',
                  marginLeft: '10px',
                  '& .MuiInputBase-root': {
                    padding: 0,
                  },
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                  },
                }}
                value={importJDText}
                onChange={handleJDImportTextFieldChange}
              />
            </Stack>
          )}
        </RadioGroup>
      </FormControl>
    </Stack>
  );
};

export default SelectJobDescription;
