import { isEmpty } from 'ramda';
import { useEffect, useMemo, useState } from 'react';
import type { Control, FieldValues } from 'react-hook-form';
import { useForm } from 'react-hook-form';

import { Checkbox, Stack, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import { monthList, yearsList } from '../../../constants';
import type { VolunteerExperienceInfo } from '../../../interface';
import { isNotNilOrEmpty } from '../../../utils';
import {
  filterMonthList,
  filterYearList,
  getIntegerMonth,
  getStringMonth,
  isValidMonth,
  validateEndYear,
  validateStartYear,
} from '../../../utils/dateUtils';
import FormActions from '../../Form/FormActions';
import RichTextEditor from '../../RichTextEditor/RichTextEditor';
import Dropdown from '../../common/Dropdown';
import InputLabel from '../../common/InputLabel';

interface VolunteerExperienceEditorProps {
  // Define a prop to accept the callback function
  onUpdate: (volunteerExperience: VolunteerExperienceInfo, method: string) => void;
  defaultVolunteerExperience?: VolunteerExperienceInfo;
  resumeId?: string;
}

const VolunteerExperienceEditor = ({
  onUpdate,
  defaultVolunteerExperience,
}: VolunteerExperienceEditorProps) => {
  const [description, setDescription] = useState('');
  const method = defaultVolunteerExperience?._id === '' ? 'add' : 'edit';

  const FormDefaultValues = useMemo(
    () => ({
      _id: defaultVolunteerExperience?._id || '',
      organization: defaultVolunteerExperience?.organization || '',
      location: defaultVolunteerExperience?.location || '',
      present: defaultVolunteerExperience?.present || false,
      start_month: getStringMonth(defaultVolunteerExperience?.start_month),
      start_year: defaultVolunteerExperience?.start_year,
      end_month: getStringMonth(defaultVolunteerExperience?.end_month),
      end_year: defaultVolunteerExperience?.end_year,
    }),
    [defaultVolunteerExperience],
  );

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    watch,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: FormDefaultValues,
  });

  const isWorking = watch('present');

  // Refresh form values on defeaultWorkExperience changes; usually happens on page refresh
  useEffect(() => {
    if (isNotNilOrEmpty(defaultVolunteerExperience)) {
      reset(FormDefaultValues);
      setDescription(defaultVolunteerExperience?.description || '');
    }
  }, [FormDefaultValues, defaultVolunteerExperience, reset, setValue]);

  const onSubmit = (data: FieldValues) => {
    onUpdate(
      {
        _id: data._id,
        organization: data.organization,
        location: data.location,
        present: data.present,
        start_year: data.start_year === '' ? null : data.start_year,
        end_year: data.end_year === '' ? null : data.end_year,
        start_month:
          isValidMonth(data.start_month) || isEmpty(data.start_month)
            ? getIntegerMonth(data.start_month)
            : (defaultVolunteerExperience?.start_month as number | null),
        end_month:
          isValidMonth(data.end_month) || isEmpty(data.end_month)
            ? getIntegerMonth(data.end_month)
            : (defaultVolunteerExperience?.end_month as number | null),
        description,
      },
      method,
    );
  };

  const onValidateStartYear = (startYear: number | null | undefined) => {
    clearErrors('end_year');
    return validateStartYear(
      getValues('start_month'),
      startYear,
      getValues('end_month'),
      getValues('end_year'),
      getValues('present'),
    );
  };

  const onValidateEndYear = (endYear: number | null | undefined) => {
    if (errors?.start_year) {
      return true;
    }
    return validateEndYear(getValues('start_year'), getValues('end_month'), endYear);
  };

  const onFilterStartYearList = (startYear: number) =>
    filterYearList(
      getValues('start_month'),
      startYear,
      getValues('end_month'),
      getValues('end_year'),
      getValues('present'),
    );

  const onFilterEndYearList = (endYear: number) =>
    filterYearList(
      getValues('start_month'),
      getValues('start_year'),
      getValues('end_month'),
      endYear,
      getValues('present'),
    );

  const onFilterStartMonthList = (startMonth: string) =>
    filterMonthList(
      startMonth,
      getValues('start_year'),
      getValues('end_month'),
      getValues('end_year'),
      getValues('present'),
    );

  const onFilterEndMonthList = (endMonth: string) =>
    filterMonthList(
      getValues('start_month'),
      getValues('start_year'),
      endMonth,
      getValues('end_year'),
      getValues('present'),
    );

  const onRichTextEditorChange = (text: string) => {
    setDescription(text);
  };

  const onSaveClick = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Stack gap={3}>
        <Stack gap={1}>
          <InputLabel htmlFor="organization" label="Organization" required />
          <TextField
            placeholder="Borderless Coders"
            {...register('organization', {
              required: 'Enter a organization name',
            })}
            sx={{
              '& .MuiOutlinedInput-root': {
                border: '1px solid',
                borderColor: 'border.light',
                borderRadius: '8px',
                padding: 0,
                '& .MuiInputBase-input': {
                  padding: '10px',
                },
                '& fieldset': {
                  border: 0,
                },
              },
            }}
            inputProps={{
              maxLength: 64,
            }}
            variant="outlined"
            error={!!errors.organization}
            fullWidth
          />
          {!!errors?.organization && (
            <Typography variant="assistive" color="error">
              {errors?.organization?.message?.toString()}
            </Typography>
          )}
        </Stack>
        <Stack gap={1}>
          <InputLabel htmlFor="location" label="Location" />
          <TextField
            id="location"
            placeholder="Saratoga, CA"
            {...register('location')}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid',
                  borderColor: 'border.light',
                  borderRadius: '8px',
                  padding: '10px',
                },
              },
            }}
            inputProps={{
              maxLength: 64,
            }}
            variant="outlined"
            fullWidth
          />
        </Stack>
        <Grid2 alignItems="center" spacing={1} container>
          <Grid2>
            <Checkbox
              checked={isWorking}
              {...register('present')}
              color="primary"
              sx={{ padding: 0 }}
            />
          </Grid2>
          <Grid2>
            <Typography variant="body3">I currently volunteer here</Typography>
          </Grid2>
        </Grid2>
        <Stack gap={1}>
          <InputLabel label="From" />
          <Grid2 justifyContent="space-between" spacing={1} container>
            <Grid2 xs={6}>
              <Dropdown
                {...register('start_month')}
                items={monthList}
                placeholder="Select Start Month"
                defaultValue={getStringMonth(defaultVolunteerExperience?.start_month)}
                control={control as unknown as Control<FieldValues>}
                disableOptionfilter={onFilterStartMonthList}
              />
            </Grid2>
            <Grid2 xs={6}>
              <Dropdown
                {...register('start_year', { validate: onValidateStartYear })}
                items={yearsList}
                placeholder="Select Start Year"
                defaultValue={defaultVolunteerExperience?.start_year}
                control={control as unknown as Control<FieldValues>}
                filterOptions={(year) => !onFilterStartYearList(year as number)}
              />
            </Grid2>
          </Grid2>
        </Stack>
        {!isWorking && (
          <Stack gap={1}>
            <InputLabel label="To" />
            <Grid2 justifyContent="space-between" spacing={1} container>
              <Grid2 xs={6}>
                <Dropdown
                  {...register('end_month')}
                  items={monthList}
                  placeholder="Select End Month"
                  defaultValue={getStringMonth(defaultVolunteerExperience?.end_month)}
                  control={control as unknown as Control<FieldValues>}
                  disableOptionfilter={onFilterEndMonthList}
                />
              </Grid2>
              <Grid2 xs={6}>
                <Dropdown
                  {...register('end_year', { validate: onValidateEndYear })}
                  items={yearsList}
                  placeholder="Select End Year"
                  defaultValue={defaultVolunteerExperience?.end_year}
                  control={control as unknown as Control<FieldValues>}
                  filterOptions={(year) => !onFilterEndYearList(year as number)}
                />
              </Grid2>
            </Grid2>
          </Stack>
        )}
        <Stack gap={1}>
          <InputLabel label="Description" />

          {/* Rich text editor */}
          <RichTextEditor
            placeholder="Provided Python classes to kids"
            html={defaultVolunteerExperience?.description || ''}
            onChange={onRichTextEditorChange}
          />
        </Stack>
        <FormActions sx={{ marginTop: '24px' }} onSubmit={onSaveClick} />
      </Stack>
    </form>
  );
};

export default VolunteerExperienceEditor;
