import { isNotNil } from 'ramda';
import type React from 'react';
import { useCallback, useLayoutEffect, useState } from 'react';

import { useWindowSize } from '@uidotdev/usehooks';

import { useAppBarStore } from '../stores/AppBarStore';
import useResponsiveDevice from './useResponsiveDevice';

/**
 * A custom hook that adjusts the app bar content and controls based on the screen size
 * and device type (mobile vs desktop). It listens for window resizing and sets the
 * app bar appropriately for mobile or desktop views.
 *
 * @param {React.ReactNode} content - The content to display in the app bar (usually for mobile).
 * @param {React.ReactNode} controls - The controls to display in the app bar (usually for mobile).
 */
const useResponsiveAppBar = (content: React.ReactNode, controls: React.ReactNode) => {
  const { width: windowWidth } = useWindowSize();
  const { isMobile } = useResponsiveDevice();
  const [isMobileBreakpoint, setIsMobileBreakpoint] = useState(isMobile);
  const [isAppbarRendered, setIsAppBarRendered] = useState(false);

  const { setAppBar, resetToDesktopAppBar } = useAppBarStore();

  /**
   * Handles setting the app bar based on the window width.
   * - If the window width is less than 600px, the mobile app bar is rendered.
   * - If the window width is greater than 600px, the desktop app bar is rendered.
   */
  const handleAppBar = useCallback(() => {
    if (isNotNil(windowWidth)) {
      if (windowWidth < 600 && !isMobileBreakpoint) {
        // set appbar for mobile on screen resize
        setIsMobileBreakpoint(true);
        setAppBar(content, controls);
      } else if (windowWidth > 600 && isMobileBreakpoint) {
        // set appbar for tablet and desktop on screen resize
        setIsMobileBreakpoint(false);
        resetToDesktopAppBar();
      } else if (!isAppbarRendered) {
        // set appbar for initial render
        if (windowWidth < 600) {
          // set appbar for mobile
          setAppBar(content, controls);
        } else {
          // set appbar for tablet and desktop
          resetToDesktopAppBar();
        }
        setIsAppBarRendered(true);
      }
    }
  }, [
    windowWidth,
    isMobileBreakpoint,
    isAppbarRendered,
    setAppBar,
    content,
    controls,
    resetToDesktopAppBar,
  ]);

  useLayoutEffect(() => {
    handleAppBar();
    setTimeout(handleAppBar, 1000); // throttling to avoid too many renders
  }, [handleAppBar, setAppBar, windowWidth]);
};

export default useResponsiveAppBar;
