import { useMemo, useState } from 'react';

import { Stack, Typography, useTheme } from '@mui/material';

import List from '../../../../../components/List';
import AiCustomizeButton from '../../../../../components/common/AiCustomizeButton';
import Alert from '../../../../../components/common/Alert';
import Button from '../../../../../components/common/Button';
import type { CardProps } from '../../../../../components/common/Card';
import Icon from '../../../../../components/common/Icon';
import Modal from '../../../../../components/common/Modal/Modal';
import ModalActions from '../../../../../components/common/Modal/ModalActions';
import ModalDetail from '../../../../../components/common/Modal/ModalDetail';
import StyledCard from '../../../../../components/common/StyledCard';
import useIsMobile from '../../../../../hooks/useIsMobile';
import TextWrappedLinkButton from '../TextWrappedLinkButton';

interface RecommendationsProps {
  recommendations: string[];
  isAiCustomized: boolean;
  score: number;
  onAiCustomizeResume: () => void;
  isAiCustomizeResumeLoading?: boolean;
  cardProps?: CardProps;
}

const Recommendations = ({
  isAiCustomized,
  recommendations,
  score,
  onAiCustomizeResume,
  isAiCustomizeResumeLoading,
  cardProps,
}: RecommendationsProps) => {
  const [showModal, setShowModal] = useState(false);
  const isMobile = useIsMobile();
  const theme = useTheme();

  const modalContent = useMemo(() => {
    if (isAiCustomized) {
      return {
        title: 'Why can’t I AI-Customize this Resume?',
        description:
          'If the AI-customized resume is customized by AI again, the text might become mechanical and cluttered with keywords due to overfitting. This is why we do not support it.',
      };
    }
    if (score >= 75) {
      return {
        title: 'AI-Customize this Resume?',
        description:
          'AI Customize can enhance your resume further for human reviewers. But, it could bring down your score a bit due to your high score.',
      };
    }
    if (score <= 50) {
      return {
        title: 'AI-Customize this Resume?',
        description:
          'Your match score is too low. Utilizing AI Customize can improve your score, but be aware that you may feel the customized resume does not reflect your professional authenticity.',
      };
    }
    return {
      title: '',
      description: '',
    };
  }, [isAiCustomized, score]);

  const handleOnClick = () => {
    if (isAiCustomized) {
      setShowModal(true);
    } else if (score > 50 && score < 75) {
      onAiCustomizeResume();
    } else {
      setShowModal(true);
    }
  };

  return (
    <StyledCard {...cardProps}>
      <Typography variant="h3">{recommendations?.[0].replace('.', '')}</Typography>
      <List
        sx={{ padding: 0 }}
        dataList={recommendations}
        listItemProps={{ sx: { padding: 0.5 } }}
        listItemTextProps={{ primaryTypographyProps: { variant: 'body2' } }}
      />
      {isAiCustomized ? (
        <Alert severity="info" muiAlertIconStyles={{ display: isMobile ? 'none' : 'block' }}>
          {isMobile && (
            <Stack
              direction="row"
              gap={1}
              sx={{ flexDirection: 'row', gap: 1, paddingBottom: 1.5 }}
            >
              <Icon
                className="fi fi-rr-info"
                fontSize="17px"
                style={{ color: theme.palette.text.secondary }}
              />
              <Typography variant="label2" sx={{ color: 'text.primaryGray' }}>
                Info
              </Typography>
            </Stack>
          )}
          <Typography component={isMobile ? 'span' : 'div'} variant="body3">
            Looking for an AI Customize option?{' '}
          </Typography>
          <TextWrappedLinkButton
            text={{
              startingText:
                'It is not available because this resume has been already customized by AI.',
              linkText: 'Why?',
              endingText: '',
            }}
            onClick={handleOnClick}
            variant="body3"
          />
        </Alert>
      ) : (
        <AiCustomizeButton onClick={handleOnClick} />
      )}
      <Modal open={showModal} contentSx={{ margin: 2, width: { xs: '15rem', sm: '30rem' } }}>
        <>
          <ModalDetail {...modalContent} />
          {isAiCustomized ? (
            <ModalActions
              sx={{ justifyContent: { xs: 'center', sm: 'flex-end' } }}
              actions={
                <Button
                  onClick={() => setShowModal(false)}
                  sx={{
                    width: { xs: '100%', sm: 'fit-content' },
                    padding: { xs: '16px 12px', sm: '8px 12px' },
                  }}
                >
                  Got it
                </Button>
              }
            />
          ) : (
            <ModalActions
              sx={{ justifyContent: { xs: 'center', sm: 'flex-end' } }}
              onConfirm={onAiCustomizeResume}
              onClose={() => setShowModal(false)}
              isLoading={isAiCustomizeResumeLoading}
            />
          )}
        </>
      </Modal>
    </StyledCard>
  );
};
export default Recommendations;
