import { useState } from 'react';

import { Typography } from '@mui/material';

import TextWrappedLinkButton from '../../pages/JobScore/ResumeScore/components/TextWrappedLinkButton';
import Button from './Button';
import Modal from './Modal/Modal';
import ModalActionsDeprecated from './Modal/ModalActions_deprecated';
import ModalDetail from './Modal/ModalDetail';

interface HelperTextWithModalProps {
  modalTitle: string;
  modalContent: string | string[] | JSX.Element;
  buttonText: string;
}

const HelperTextWithModal = ({
  modalTitle,
  modalContent,
  buttonText,
}: HelperTextWithModalProps) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <TextWrappedLinkButton
        text={{
          startingText: ' ',
          linkText: buttonText,
          endingText: '',
        }}
        onClick={() => setShowModal(true)}
        component="span"
        variant="inherit"
      />
      <Modal open={showModal} contentSx={{ width: { xs: '70vw', md: '650px' } }}>
        <>
          <ModalDetail
            title={modalTitle}
            description={
              Array.isArray(modalContent) ? (
                <ul
                  style={{
                    marginBlockStart: 0,
                    marginBlockEnd: 0,
                    paddingInlineStart: '20px',
                  }}
                >
                  {modalContent.map((item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={index} style={{ paddingBottom: '8px' }}>
                      <Typography variant="body3">{item}</Typography>
                    </li>
                  ))}
                </ul>
              ) : (
                modalContent
              )
            }
          />
          <ModalActionsDeprecated
            actions={
              <Button color="primary" onClick={() => setShowModal(false)}>
                Got it
              </Button>
            }
          />
        </>
      </Modal>
    </>
  );
};

export default HelperTextWithModal;
