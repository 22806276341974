import { useState } from 'react';

import { Global } from '@emotion/react';
import {
  IconButton,
  SwipeableDrawer as MuiSwipeableDrawer,
  Tooltip as MuiTooltip,
  Stack,
  Typography,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import Button from './Button';
import Icon from './Icon';
import { Puller } from './SwipeableDrawer';

const drawerBleeding = 56;

const DrawerContent = styled(Stack)({
  height: '100%',
  padding: 16,
  alignItems: 'center',
});

interface MobileTooltipProps {
  content: string | string[];
}

const MobileTooltip = ({ content }: MobileTooltipProps) => {
  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setOpenDrawer(!openDrawer);
  };

  return (
    <>
      <MuiTooltip title={undefined}>
        <IconButton onClick={toggleDrawer}>
          <Icon className="fi fi-rr-info" fontSize="16px" />
        </IconButton>
      </MuiTooltip>
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: 'auto',
            overflow: 'visible',
            borderRadius: '12px 12px 0px 0px',
          },
        }}
      />
      <MuiSwipeableDrawer
        anchor="bottom"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen
        ModalProps={{
          keepMounted: true,
        }}
        sx={{ zIndex: theme.zIndex.drawer + 1 }}
      >
        <DrawerContent>
          <Puller sx={{ marginBottom: 3 }} />
          <Stack sx={{ gap: 2, width: '100%' }}>
            <Typography variant="h3">Info</Typography>
            {typeof content === 'string' ? (
              <Typography variant="body3">{content}</Typography>
            ) : (
              <ul
                style={{
                  marginBlockStart: 0,
                  marginBlockEnd: 0,
                  paddingInlineStart: '24px',
                }}
              >
                {content.map((item, index) => (
                  <li
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    style={{ paddingBottom: index !== content.length - 1 ? '8px' : '0' }}
                  >
                    <Typography variant="body3">{item}</Typography>
                  </li>
                ))}
              </ul>
            )}
            <Button
              onClick={() => setOpenDrawer(false)}
              sx={{
                marginTop: 2,
                width: '100%',
                padding: '16px 12px',
              }}
            >
              Got it
            </Button>
          </Stack>
        </DrawerContent>
      </MuiSwipeableDrawer>
    </>
  );
};

export default MobileTooltip;
