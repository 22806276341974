import ResumeEditor from '../components/ResumeEditor';
import AiCustomizedResume from '../pages/AiCustomizedResume';
import CreateJob from '../pages/Create/CreateJobDescription';
import CreateJobMatch from '../pages/Create/CreateJobMatch';
import CreateResume from '../pages/Create/CreateResume';
import JobMatchesPage from '../pages/JobMatches';
import JobScore from '../pages/JobScore';
import JobXrayView from '../pages/JobXrayView';
import JobXrays from '../pages/JobXrays';
import OnboardingPage from '../pages/Onboarding';
import ProfileSettings from '../pages/ProfileSettings';
import ResumePage from '../pages/ResumePage';
import ResumeReorder from '../pages/ResumeReorder';
import Resumes from '../pages/Resumes';
import SignInPage from '../pages/SignIn';
import SignUpPage from '../pages/SignUp';
import VerificationPage from '../pages/Verification';
import RoutePaths from './RoutePaths';

export const publicRoutes = [
  {
    path: RoutePaths.HOME,
    // TODO: check for onboarding flag
    element: <JobMatchesPage />,
  },
  {
    path: RoutePaths.SIGN_IN,
    element: <SignInPage />,
  },
  {
    path: RoutePaths.SIGN_UP,
    element: <SignUpPage />,
  },
  {
    path: RoutePaths.VERIFICATION,
    element: <VerificationPage />,
  },
];

export const protectedRoutes = [
  {
    path: RoutePaths.ONBOARDING,
    element: <OnboardingPage />,
  },
  {
    path: RoutePaths.RESUMES,
    element: <Resumes />,
  },
  {
    path: RoutePaths.JOBMATCHES,
    element: <JobMatchesPage />,
  },
  {
    path: RoutePaths.JOB_SCORE,
    element: <JobScore />,
  },
  {
    path: RoutePaths.JOBS,
    element: <JobXrays />,
  },
  {
    path: RoutePaths.RESUME_HANDLER,
    element: <ResumePage />,
  },
  { path: RoutePaths.RESUME_EDIT, element: <ResumeEditor /> },
  {
    // TODO: use nested routes
    path: RoutePaths.RESUME_REORDER,
    element: <ResumeReorder />,
  },
  {
    path: RoutePaths.CREATE_JOB_MATCH,
    element: <CreateJobMatch />,
  },
  {
    path: RoutePaths.CREATE_RESUME,
    element: <CreateResume />,
  },
  {
    path: RoutePaths.CREATE_JOB,
    element: <CreateJob />,
  },
  { path: RoutePaths.AI_CUSTOMIZED, element: <AiCustomizedResume /> },
  { path: RoutePaths.JOB_XRAY, element: <JobXrayView /> },
  { path: RoutePaths.SETTINGS, element: <ProfileSettings /> },
];

export const routes = [
  ...publicRoutes,
  ...protectedRoutes,
  // TODO: add 404 page
];

export default routes;
