import { isEmpty } from 'ramda';
import { useEffect, useMemo, useState } from 'react';
import type { Control, FieldValues } from 'react-hook-form';
import { useForm } from 'react-hook-form';

import { Checkbox, InputLabel, Stack, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import { monthList, yearsList } from '../../../constants';
import type { ProjectInfo } from '../../../interface';
import { isNotNilOrEmpty } from '../../../utils';
import {
  filterMonthList,
  filterYearList,
  getIntegerMonth,
  getStringMonth,
  isValidMonth,
  validateEndYear,
  validateStartYear,
} from '../../../utils/dateUtils';
import FormActions from '../../Form/FormActions';
import RichTextEditor from '../../RichTextEditor/RichTextEditor';
import Dropdown from '../../common/Dropdown';

interface ProjectEditorProps {
  onUpdate: (project: ProjectInfo, method: string) => void;
  defaultProject?: ProjectInfo;
}

const ProjectEditor = ({ onUpdate, defaultProject }: ProjectEditorProps) => {
  const [description, setDescription] = useState('');
  const method = defaultProject?._id === '' ? 'add' : 'edit';

  const FormDefaultValues = useMemo(
    () => ({
      _id: defaultProject?._id || '',
      project_name: defaultProject?.project_name || '',
      organization: defaultProject?.organization || '',
      description: defaultProject?.description || '',
      present: defaultProject?.present || false,
      start_month: getStringMonth(defaultProject?.start_month),
      start_year: defaultProject?.start_year,
      end_month: getStringMonth(defaultProject?.end_month),
      end_year: defaultProject?.end_year,
      index: defaultProject?.index || 0, // Provide a default value for index
    }),
    [defaultProject],
  );

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    getValues,
    watch,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: FormDefaultValues,
  });

  const isWorking = watch('present');

  // Refresh form values on defeaultWorkExperience changes; usually happens on page refresh
  useEffect(() => {
    if (isNotNilOrEmpty(defaultProject)) {
      reset(FormDefaultValues);
      setDescription(defaultProject?.description || '');
    }
  }, [FormDefaultValues, defaultProject, reset, setValue]);

  const onSubmit = (data: FieldValues) => {
    onUpdate(
      {
        _id: data._id,
        project_name: data.project_name,
        organization: data.organization,
        present: data.present,
        start_year: data.start_year === '' ? null : data.start_year,
        end_year: data.end_year === '' ? null : data.end_year,
        start_month:
          isValidMonth(data.start_month) || isEmpty(data.start_month)
            ? getIntegerMonth(data.start_month)
            : (defaultProject?.start_month as number | null),
        end_month:
          isValidMonth(data.end_month) || isEmpty(data.end_month)
            ? getIntegerMonth(data.end_month)
            : (defaultProject?.end_month as number | null),
        description,
        index: data.index,
      },
      method,
    );
  };

  const onValidateStartYear = (startYear: number | null | undefined) => {
    clearErrors('end_year');
    return validateStartYear(
      getValues('start_month'),
      startYear,
      getValues('end_month'),
      getValues('end_year'),
      getValues('present'),
    );
  };

  const onValidateEndYear = (endYear: number | null | undefined) => {
    if (errors?.start_year) {
      return true;
    }
    return validateEndYear(getValues('start_year'), getValues('end_month'), endYear);
  };

  const onFilterStartYearList = (startYear: number) =>
    filterYearList(
      getValues('start_month'),
      startYear,
      getValues('end_month'),
      getValues('end_year'),
      getValues('present'),
    );

  const onFilterEndYearList = (endYear: number) =>
    filterYearList(
      getValues('start_month'),
      getValues('start_year'),
      getValues('end_month'),
      endYear,
      getValues('present'),
    );

  const onFilterStartMonthList = (startMonth: string) =>
    filterMonthList(
      startMonth,
      getValues('start_year'),
      getValues('end_month'),
      getValues('end_year'),
      getValues('present'),
    );

  const onFilterEndMonthList = (endMonth: string) =>
    filterMonthList(
      getValues('start_month'),
      getValues('start_year'),
      endMonth,
      getValues('end_year'),
      getValues('present'),
    );

  const onRichTextEditorChange = (text: string) => {
    setDescription(text);
  };

  const onSaveClick = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap="24px">
        <Stack gap="8px">
          <InputLabel
            htmlFor="project_name"
            sx={{
              color: 'error.main',
              '& .MuiFormLabel-asterisk': { paddingLeft: 0.5, fontSize: '12px' },
            }}
            required
          >
            <Typography variant="label2" color="text.primary">
              Project Name
            </Typography>
          </InputLabel>
          {/* TODO: Use common TextField component */}
          <TextField
            id="project_name"
            placeholder="Open Source PDF Editor"
            {...register('project_name', {
              required: 'Enter a project name',
            })}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid',
                  borderColor: 'border.light',
                  borderRadius: '8px',
                  padding: '10px',
                },
              },
            }}
            variant="outlined"
            error={!!errors.project_name}
            fullWidth
          />
          {!!errors?.project_name && (
            <Typography variant="assistive" color="error">
              {errors?.project_name?.message?.toString()}
            </Typography>
          )}
        </Stack>
        <Stack gap="8px">
          <InputLabel>
            <Stack>
              <Typography variant="label2" color="text.primary">
                Organization
              </Typography>
              <Typography variant="body3" color="text.secondary">
                If the project was for your client, enter the name of the client company or
                organization. If it was an academic project, enter the school name.
              </Typography>
            </Stack>
          </InputLabel>
          <TextField
            id="organization"
            placeholder="San Jose State University"
            {...register('organization')}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid',
                  borderColor: 'border.light',
                  borderRadius: '8px',
                  padding: '10px',
                },
              },
            }}
            variant="outlined"
            fullWidth
          />
        </Stack>
        <Grid2 alignItems="center" spacing="8px" container>
          <Grid2>
            <Checkbox checked={isWorking} {...register('present')} color="primary" />
          </Grid2>
          <Grid2>
            <Typography variant="body3">I am currently working on this project</Typography>
          </Grid2>
        </Grid2>
        <Stack gap="8px">
          <InputLabel>
            <Typography variant="label2" color="text.primary">
              From
            </Typography>
          </InputLabel>
          <Grid2 justifyContent="space-between" spacing="8px" container>
            <Grid2 xs={6}>
              <Dropdown
                {...register('start_month')}
                items={monthList}
                placeholder="Select Start Month"
                defaultValue={getStringMonth(defaultProject?.start_month)}
                control={control as unknown as Control<FieldValues>}
                disableOptionfilter={onFilterStartMonthList}
              />
            </Grid2>
            <Grid2 xs={6}>
              <Dropdown
                {...register('start_year', { validate: onValidateStartYear })}
                items={yearsList}
                placeholder="Select Start Year"
                defaultValue={defaultProject?.start_year}
                control={control as unknown as Control<FieldValues>}
                filterOptions={(year) => !onFilterStartYearList(year as number)}
              />
            </Grid2>
          </Grid2>
        </Stack>
        {!isWorking && (
          <Stack gap="8px">
            <InputLabel>
              <Typography variant="label2" color="text.primary">
                To
              </Typography>
            </InputLabel>
            <Grid2 justifyContent="space-between" spacing="8px" container>
              <Grid2 xs={6}>
                <Dropdown
                  {...register('end_month')}
                  items={monthList}
                  placeholder="Select End Month"
                  defaultValue={getStringMonth(defaultProject?.end_month)}
                  control={control as unknown as Control<FieldValues>}
                  disableOptionfilter={onFilterEndMonthList}
                />
              </Grid2>
              <Grid2 xs={6}>
                <Dropdown
                  {...register('end_year', { validate: onValidateEndYear })}
                  items={yearsList}
                  placeholder="Select End Year"
                  defaultValue={defaultProject?.end_year}
                  control={control as unknown as Control<FieldValues>}
                  filterOptions={(year) => !onFilterEndYearList(year as number)}
                />
              </Grid2>
            </Grid2>
          </Stack>
        )}
        <Stack gap="8px">
          <Stack gap="4px">
            <Typography variant="label2">Description</Typography>
            <Typography variant="body3">
              Describe your achievements, key tasks, responsibilities, and impacts on the team.
            </Typography>
          </Stack>

          {/* Rich text editor */}
          <RichTextEditor
            placeholder="Enter description"
            html={defaultProject?.description || ''}
            onChange={onRichTextEditorChange}
          />
        </Stack>
        <FormActions sx={{ marginTop: '24px' }} onSubmit={onSaveClick} />
      </Stack>
    </form>
  );
};

export default ProjectEditor;
