import { isNotNil } from 'ramda';
import type { ReactNode } from 'react';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import type { SxProps } from '@mui/material';
import {
  Box,
  FormHelperText,
  IconButton,
  InputLabel,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

import TextWrappedLinkButton from '../../pages/JobScore/ResumeScore/components/TextWrappedLinkButton';
import Button from '../common/Button';
import Icon from '../common/Icon';
import Modal from '../common/Modal/Modal';
import ModalActionsDeprecated from '../common/Modal/ModalActions_deprecated';
import ModalDetail from '../common/Modal/ModalDetail';

interface FileDropzoneProps {
  onFileChange: (file: File | undefined) => void;
  hideLabel?: boolean;
  label?: string;
  defaultValue?: File;
  helperText?: string | ReactNode;
  sx?: SxProps;
}

enum DropzoneState {
  DEFAULT = 'default',
  SELECTED = 'selected',
  DRAG = 'drag',
  ERROR = 'error',
}

const dropzoneColor = {
  [DropzoneState.DEFAULT]: 'border.light',
  [DropzoneState.SELECTED]: 'border.light',
  [DropzoneState.DRAG]: 'primary.main',
  [DropzoneState.ERROR]: 'error.main',
};

const FileDropzone = ({
  onFileChange,
  hideLabel,
  label,
  helperText,
  defaultValue,
  sx,
}: FileDropzoneProps) => {
  const [file, setFile] = useState<File | undefined>(defaultValue);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [dropzoneState, setDropzoneState] = useState<DropzoneState>(DropzoneState.DEFAULT);

  const theme = useTheme();

  const onDrop = useCallback(
    (uploadedFile: File[]) => {
      onFileChange(uploadedFile[0]);
      setFile(uploadedFile[0]);
      setDropzoneState(DropzoneState.SELECTED);
    },
    [onFileChange],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDragEnter: () => setDropzoneState(DropzoneState.DRAG),
    onDragLeave: () => setDropzoneState(DropzoneState.DEFAULT),
    onDropRejected: () => setDropzoneState(DropzoneState.ERROR),
    multiple: false,
    accept: {
      'application/pdf': ['.pdf'],
    },
  });

  const handleRemoveResumeFile = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setFile(undefined);
      onFileChange(undefined);
      setDropzoneState(DropzoneState.DEFAULT);
    },
    [onFileChange],
  );

  return (
    <Box>
      {!hideLabel && (
        <Stack>
          <InputLabel
            htmlFor="resume"
            sx={{
              color: 'error.main',
              '& .MuiFormLabel-asterisk': { paddingLeft: 0.5, fontSize: '12px' },
            }}
            required
          >
            <Typography variant="label2" color="text.primary">
              {label || 'Resume'}
            </Typography>
          </InputLabel>
          <FormHelperText sx={{ marginTop: '0px', marginBottom: '8px' }}>
            {helperText || (
              <TextWrappedLinkButton
                variant="body3"
                text={{
                  startingText: 'Importing a new resume creates a base resume for JobQuest. ',
                  linkText: 'Base Resume',
                  endingText: '',
                }}
                onClick={() => setShowModal(true)}
              />
            )}
          </FormHelperText>
          <Modal open={showModal} contentSx={{ width: '500px' }}>
            <>
              <ModalDetail
                title="What is a Base Resume?"
                description="Importing a new resume creates a base resume that you can duplicate, edit, AI customize, and download. Using the base resume, you can easily create many versions of resumes for different job positions. Avoid importing the same resume twice."
              />
              <ModalActionsDeprecated
                actions={
                  <Button color="primary" onClick={() => setShowModal(false)}>
                    <Typography variant="label2">Got it</Typography>
                  </Button>
                }
              />
            </>
          </Modal>
        </Stack>
      )}
      <Box {...getRootProps()} sx={sx}>
        <input {...getInputProps()} />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '40px',
            border: dropzoneState === DropzoneState.DRAG ? '1.5px dashed' : '1px solid',
            borderColor: dropzoneColor[dropzoneState],
            borderRadius: '8px',
            padding: '20px 12px',
            cursor: 'pointer',
            gap: '12px',
            '&:hover': {
              borderColor: 'border.main',
            },
            '&:focus-visible': {
              outlineColor: 'primary.main',
            },
          }}
        >
          {dropzoneState === DropzoneState.DRAG ? (
            <Typography variant="label2" sx={{ color: 'primary.main' }}>
              Drop your file here to upload
            </Typography>
          ) : (
            <>
              <Icon
                className={`fi ${
                  dropzoneState === DropzoneState.SELECTED ? 'fi-rr-file' : 'fi-rr-add-document'
                }`}
                fontSize="32px"
                color={theme.palette.text.secondary}
              />
              {dropzoneState === DropzoneState.SELECTED ? (
                <Stack sx={{ textAlign: 'left', flexGrow: 1 }}>
                  <Typography variant="body3">{file?.name}</Typography>
                  <Typography variant="assistive" color="grey.600">
                    Ready to upload
                  </Typography>
                </Stack>
              ) : (
                <Stack sx={{ textAlign: 'left', flexGrow: 1 }}>
                  <Typography variant="body3">Select a resume PDF file to upload</Typography>
                  <Typography variant="assistive" color="grey.600">
                    or drop your file here
                  </Typography>
                </Stack>
              )}
              {isNotNil(file) && (
                <IconButton
                  aria-label="remove icon"
                  size="small"
                  component="button"
                  onClick={handleRemoveResumeFile}
                >
                  <Icon className="fi fi-rr-cross-small" />
                </IconButton>
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default FileDropzone;
