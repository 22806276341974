import { isEmpty } from 'ramda';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Stack, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import { monthList, yearsList } from '../../../constants';
import type { CertificatesLicensesInfo } from '../../../interface';
import {
  getIntegerMonth,
  getStringMonth,
  isValidMonth,
  validateIssuedYear,
} from '../../../utils/dateUtils';
import FormActions from '../../Form/FormActions';
import Dropdown from '../../common/Dropdown';
import InputLabel from '../../common/InputLabel';

interface CertificateLicenseEditorProps {
  // Define a prop to accept the callback function
  onUpdate: (certificateLicense: CertificatesLicensesInfo, method: string) => void;
  defaultCertificateLicense?: CertificatesLicensesInfo;
  resumeId?: string;
}

export const CertificateLicenseEditor = ({
  onUpdate,
  defaultCertificateLicense,
}: CertificateLicenseEditorProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm();

  const method = defaultCertificateLicense?._id === '' ? 'add' : 'edit';

  // Define a function to handle the form submission
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (data: any) => {
    onUpdate(
      {
        ...defaultCertificateLicense,
        ...data,
        issued_year: data.issued_year === '' ? null : data.issued_year,
        issued_month:
          isValidMonth(data.issued_month) || isEmpty(data.issued_month)
            ? getIntegerMonth(data.issued_month)
            : defaultCertificateLicense?.issued_month,
      },
      method,
    );
  };

  // TODO: Rework on setting default values for the inputs
  useEffect(() => {
    setValue('organization', defaultCertificateLicense?.organization || '', {
      shouldValidate: false,
    });
    setValue('name', defaultCertificateLicense?.name);
    setValue('issued_month', getStringMonth(defaultCertificateLicense?.issued_month));
    setValue('issued_year', defaultCertificateLicense?.issued_year);
  }, [setValue, defaultCertificateLicense]);

  const onValidateIssuedYear = (issuedYear: number) => {
    if (!validateIssuedYear(getValues('issued_month'), issuedYear)) {
      return 'Issued year is required';
    }
    return true;
  };

  const onSaveClick = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Stack gap={3}>
        <Stack gap={1}>
          <InputLabel htmlFor="name" label="Certification or License Name" required />
          <TextField
            id="name"
            placeholder="Project Management Professional"
            {...register('name', { required: 'Enter a certification or license name' })}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid',
                  borderColor: 'border.light',
                  borderRadius: '8px',
                  padding: '10px',
                },
              },
            }}
            inputProps={{
              maxLength: 64,
            }}
            variant="outlined"
            error={!!errors.name}
            fullWidth
          />
          {!!errors?.name && (
            <Typography variant="assistive" color="error">
              {errors?.name?.message?.toString()}
            </Typography>
          )}
        </Stack>

        <Stack gap={1}>
          <InputLabel htmlFor="organization" label="Issuing Organization" />
          <TextField
            placeholder="Project Management Institute"
            {...register('organization')}
            sx={{
              '& .MuiOutlinedInput-root': {
                border: '1px solid',
                borderColor: 'border.light',
                borderRadius: '8px',
                padding: 0,
                '& .MuiInputBase-input': {
                  padding: '10px',
                },
                '& fieldset': {
                  border: 0,
                },
              },
            }}
            inputProps={{
              maxLength: 64,
            }}
            variant="outlined"
            fullWidth
          />
        </Stack>

        <Stack gap={1}>
          <InputLabel label="Issued Date" />
          <Grid2 justifyContent="space-between" spacing={1} container>
            <Grid2 xs={6}>
              <Dropdown
                {...register('issued_month')}
                items={monthList}
                placeholder="Select Issue Month"
                defaultValue={getStringMonth(defaultCertificateLicense?.issued_month)}
                control={control}
              />
            </Grid2>
            <Grid2 xs={6}>
              <Dropdown
                {...register('issued_year', { validate: onValidateIssuedYear })}
                items={yearsList}
                placeholder="Select Issue Year"
                defaultValue={defaultCertificateLicense?.issued_year}
                control={control}
              />
            </Grid2>
          </Grid2>
        </Stack>
        <FormActions sx={{ marginTop: '24px' }} onSubmit={onSaveClick} />
      </Stack>
    </form>
  );
};
