import { useState } from 'react';

import { LinearProgress, Skeleton, Stack, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import jobQuestDesktopLogo from '../../../assets/jobquest-logo.svg';
import jobQuestMobileLogo from '../../../assets/jobquest-mobile-logo.svg';
import useIsMobile from '../../../hooks/useIsMobile';
import Card from '../../common/Card';
import ProgressActions from './ProgressActions';
import ProgressStatusInfo from './ProgressStatusInfo';

/**
 * Renders a progress component with title, estimated time, information about progress, image, description points, progress text for task in progress, progress bar, and actions.
 *
 * @component
 * @param {ProgressRendererProps} props - The props for the ProgressRenderer component.
 * @param {string} props.title - The title of the progress task.
 * @param {string} props.estTime - The estimated time for the progress task.
 * @param {string} props.info - Additional assistive information for the progress task.
 * @param {string} props.image - The image source for the progress task.
 * @param {string[]} props.descriptionPoints - The description points for the progress task.
 * @param {string} props.progressText - The progress text to display when the task is in progress.
 * @param {number} props.progress - The progress value for the progress bar.
 * @param {boolean} props.isError - Indicates if there is an error with the progress.
 * @returns {JSX.Element} The rendered ProgressRenderer component.
 */

interface ProgressRendererProps {
  title: string;
  estTime: string;
  info: string;
  image: string;
  descriptionPoints: string[];
  progressText: string;
  progress: number;
  isError: boolean;
  redirectPath: string;
}

const ProgressRenderer = ({
  title,
  estTime,
  info,
  image,
  descriptionPoints,
  progressText,
  progress,
  isError,
  redirectPath,
}: ProgressRendererProps) => {
  const isMobile = useIsMobile();
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return (
    // TODO: Fix margins and padding from layout
    // quickfix, 'main' component adds 70px(4.5rem) margin top and 24px padding - need to find alternative to it
    <Stack
      sx={{
        gap: '32px',
        marginTop: '-4.5rem',
        maxWidth: '100%',
        // Commom layout is adding 4rem margin
        minHeight: 'calc(100vh - 4rem)',
        justifyContent: 'space-between',
      }}
    >
      <Stack
        sx={{ alignItems: 'flex-start', height: '50px', paddingTop: '12px', minWidth: '100%' }}
      >
        <img
          src={isMobile ? jobQuestMobileLogo : jobQuestDesktopLogo}
          alt="job-quest-logo"
          style={{ height: '100%' }}
        />
      </Stack>

      <Stack
        sx={{
          gap: 5,
          maxWidth: { sm: '600px', md: '1200px' },
          marginX: 'auto',
          flexGrow: 1,
          justifyContent: 'center',
        }}
      >
        <Card
          rootSx={{ width: 'fit-content' }}
          sx={{ borderRadius: '16px' }}
          cardContentProps={{
            sx: {
              padding: 0,
              '&:last-child': {
                paddingBottom: 0,
              },
            },
          }}
        >
          <Grid2 container>
            <Grid2
              xs={12}
              md={6}
              sx={{
                minWidth: '15.625rem',
                minHeight: '12.5rem',
                aspectRatio: 1.25,
                background: 'background.mediumLight',
              }}
            >
              <img
                style={{ width: '100%', height: '100%', display: isImageLoaded ? 'block' : 'none' }}
                src={image}
                alt={title}
                onLoad={() => setIsImageLoaded(true)}
              />
              {!isImageLoaded && (
                <Skeleton variant="rectangular" sx={{ height: '100%', width: '100%' }} />
              )}
            </Grid2>
            <Grid2
              xs={12}
              md={6}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {/* active slide details */}
              <Stack sx={{ padding: 3, gap: 3 }}>
                <Stack gap={0.25}>
                  <Stack
                    sx={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: 0.5,
                    }}
                  >
                    <Typography variant="label1">{title}</Typography>
                    <Typography variant="label2" sx={{ color: 'text.secondary' }}>
                      {estTime}
                    </Typography>
                  </Stack>
                  <Typography variant="assistive">{info}</Typography>
                </Stack>
                <ul style={{ paddingLeft: '30px', margin: 0 }}>
                  {descriptionPoints.map((point) => (
                    <li key={point} style={{ padding: '4px 0px' }}>
                      <Typography variant="body3">{point}</Typography>
                    </li>
                  ))}
                </ul>
              </Stack>
            </Grid2>
          </Grid2>
        </Card>
        <Stack gap={4} sx={{ width: '100%' }}>
          <Stack
            sx={{
              gap: 2.5,
              width: '100%',
              alignItems: 'center',
            }}
          >
            <ProgressStatusInfo isError={isError} progressText={progressText} />
            <LinearProgress
              color={isError ? 'error' : 'primary'}
              variant="determinate"
              value={progress}
              sx={{ width: '100%', height: '0.75rem', borderRadius: '5px' }}
            />
          </Stack>
          <ProgressActions continueTo={redirectPath} hideInfoMessage />
        </Stack>
      </Stack>
    </Stack>
  );
};
export default ProgressRenderer;
