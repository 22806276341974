import { useCallback, useState } from 'react';

import { IconButton, Stack, Typography, useTheme } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import BootstrapTooltip from '../../../components/common/BootstrapTooltip';
import Icon from '../../../components/common/Icon';
import Modal from '../../../components/common/Modal/Modal';
import ModalActions from '../../../components/common/Modal/ModalActions';
import ModalDetail from '../../../components/common/Modal/ModalDetail';
import SwipeableDrawer from '../../../components/common/SwipeableDrawer';
import { useSnackbar } from '../../../contexts/snackbar';
import useResponsiveDevice from '../../../hooks/useResponsiveDevice';
import { JobDescriptionTypes, deleteJobDescription } from '../../../services/jobDescriptions';
import { useSwipeableDrawerStore } from '../../../stores/SwipeableDrawerStore';

interface ControlsProps {
  id: string;
  jobCompany: string;
  jobTitle: string;
}

// TODO: refactor for generic use
// TODO create a controls manager for listing pages
export const Controls = ({ id, jobCompany, jobTitle }: ControlsProps) => {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();
  const { close: closeSwipeableDrawer } = useSwipeableDrawerStore();
  const theme = useTheme();
  const { isDesktop } = useResponsiveDevice();
  const deleteModalDescription = `Are you sure you want to delete Job X-Ray${
    jobTitle ? ` for ${jobTitle} position` : ' for position'
  }${
    jobCompany ? ` at ${jobCompany}` : ''
  }? Any associated Job Matches will also be deleted. This action cannot be undone.`;

  const { mutate: deleteJobXray, isLoading } = useMutation({
    mutationFn: deleteJobDescription,
    onSuccess: () => {
      setOpen(false);
      closeSwipeableDrawer();
      showSnackbar('success', 'Job X-Ray deleted');
      queryClient.invalidateQueries([JobDescriptionTypes.JOBXRAYS]);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      setOpen(false);
      closeSwipeableDrawer();
      showSnackbar('error', error.response?.data?.errors);
    },
  });

  const onDeleteClick = useCallback((e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    setOpen(true);
  }, []);

  const handleDeleteJobXray = useCallback(() => {
    deleteJobXray(id);
  }, [deleteJobXray, id]);

  return (
    <>
      {isDesktop ? (
        <Stack
          className="column-controls"
          sx={{
            flexDirection: 'row',
            justifyContent: 'flex-end',
            width: '100%',
            opacity: 0,
            transition: 'opacity 0.3s ease-in-out',
          }}
        >
          <BootstrapTooltip title="Delete">
            <IconButton
              aria-label="delete icon"
              size="small"
              component="button"
              onClick={onDeleteClick}
            >
              <Icon className="fi fi-br-trash" color={theme.palette.text.secondary} />
            </IconButton>
          </BootstrapTooltip>
          <Modal open={open}>
            <>
              <ModalDetail title="Delete Job X-Ray?" description={deleteModalDescription} />
              <ModalActions
                type="delete"
                onConfirm={handleDeleteJobXray}
                onClose={() => setOpen(false)}
                isLoading={isLoading}
              />
            </>
          </Modal>
        </Stack>
      ) : (
        <SwipeableDrawer>
          <Stack sx={{ alignItems: 'flex-end', gap: 0.5 }}>
            <IconButton
              aria-label="Close icon"
              size="small"
              component="button"
              onClick={closeSwipeableDrawer}
              sx={{ padding: 1.25 }}
            >
              <Icon
                className="fi fi-br-cross"
                style={{ color: theme.palette.text.secondary, fontSize: '16px' }}
              />
            </IconButton>
            <Stack sx={{ alignItems: 'flex-start', gap: 1.5 }}>
              <Typography variant="h3">Delete Job</Typography>
              <Typography variant="body3" sx={{ textAlign: 'left' }}>
                {deleteModalDescription}
              </Typography>
              <ModalActions
                type="delete"
                onConfirm={handleDeleteJobXray}
                onClose={closeSwipeableDrawer}
                sx={{ paddingTop: 1.5 }}
                isLoading={isLoading}
              />
            </Stack>
          </Stack>
        </SwipeableDrawer>
      )}
    </>
  );
};
