import type {
  ContactInfo,
  CourseworkInfo,
  EducationInfo,
  ExperienceInfo,
  InterestsInfo,
  JdResumeId,
  PatentInfo,
  ProjectInfo,
  ResponseStatus,
  ResumeData,
  ResumeKey,
  ResumeSectionType,
  SectionOrder,
  SortedResumeSectionKey,
  SummaryInfo,
} from '../interface';
import http from './http';

const R = require('ramda');

export interface Resume {
  _id: string;
  filename: string;
  name: string;
  user_id: string;
  created_at: string;
  status: ResponseStatus;
  error: string;
  path_to_s3: string;
  used_for_score: number;
  updated_at: string;
  customized_name: string;
  company_customized_for: string | null;
  is_ai_customized: boolean;
  progress: number;
}

export interface ResumeListResponse {
  resume_list: Resume[];
  success: boolean;
}

interface SkillsInfo {
  id: string;
  skills: string[];
}

export interface CareerStage {
  title: string;
  sectionOrder: SectionOrder[];
}

// its a type of object where key is string and value is CareerStage
interface CareerStagesResponse {
  [key: string]: CareerStage;
}

export enum ResumesKeys {
  RESUMES = 'resumes',
  COMPANIES = 'companies',
  TEMPLATES = 'templates',
  CAREER_STAGES = 'career_stages',
}

// upload resume & job // TODO: move to jobDescription service
export const uploadJdResume = async (jobDescription: string, resume: File) => {
  if (!jobDescription || !resume) {
    return Promise.reject(new Error('Job Description and Resume file are required'));
  }

  try {
    const formData = new FormData();
    formData.append('job_description', jobDescription);
    formData.append('resume', resume);
    const res = await http.post('/resumes/upload-jd-resume/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (res?.data?.status === 'success') {
      const response = {
        job_description_id: res?.data?.data?.job_description?._id,
        resume_id: res?.data?.data.resume?._id,
      };
      return await Promise.resolve(response);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

// get all resumes
export const getAllResumes = async (): Promise<{
  result: Resume[];
  status: {
    status: ResponseStatus;
    message: string;
  };
}> => {
  try {
    const res = await http.get('/resumes');
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

// get all processed resumes with 100 progress
export const getAllProcessedResumes = async (): Promise<Resume[]> => {
  try {
    const res = await http.get('/resumes/processed');
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

// get all resume templates
export const getResumeTemplate = async () => {
  try {
    const res = await http.get(`/resume/template`);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res?.data?.data.result);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

// get resume xray
export const getResumeXRay = async (resumeId: string): Promise<ResumeData> => {
  try {
    const res = await http.get(`/resumes/xray/${resumeId}`, {
      timeout: 5000, // Set the timeout to 5 seconds (5000 milliseconds)
    });
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

// AI customize a resume for a JD
export const GenerateAiCustomizeResume = async (jdResumeId: JdResumeId) => {
  try {
    const res = await http.post(`/resumes/${jdResumeId.resume_id}/ai_customization`, {
      job_description_id: jdResumeId.job_description_id,
    });
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

// edit contact info
export const editResumeContactInfo = async (contact: ContactInfo) => {
  // Create a copy of the object without the _id field
  const { _id: contactId, ...contactInfo } = contact;
  try {
    const res = await http.put(`/resumes/contact/${contactId}`, contactInfo);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

// add resume summary
export const addResumeSummary = async (payload: { resumeId: string; summary: SummaryInfo }) => {
  const { resumeId, summary } = payload;
  try {
    const res = await http.post(`/resumes/${resumeId}/professional_summary/`, summary);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

// edit resume summary
export const editResumeSummary = async (summary: SummaryInfo) => {
  // Create a copy of the object without the _id field
  const { _id: summaryId, ...summaryInfo } = summary;
  try {
    const res = await http.put(`/resumes/professional_summary/${summaryId}`, summaryInfo);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

//  edit skills
export const editResumeSkills = async (skills: SkillsInfo) => {
  // Create a copy of the object without the _id field
  const { id: skillsId, ...skillsInfo } = skills;
  try {
    const res = await http.put(`/resumes/skills/${skillsId}`, skillsInfo);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

//  add work experience
export const addResumeWorkExperience = async (experience: ExperienceInfo) => {
  // Create a copy of the object without the _id field
  const { _id: resumeId, ...experienceInfo } = experience;
  try {
    const res = await http.post(`/resumes/work_experience/${resumeId}`, experienceInfo);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

//  edit work experience
export const editResumeWorkExperience = async (experience: ExperienceInfo) => {
  // Create a copy of the object without the _id field
  const { _id: experienceId, ...experienceInfo } = experience;
  try {
    const res = await http.put(`/resumes/work_experience/${experienceId}`, experienceInfo);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

//  delete experience
export const deleteResumeExperience = async (experienceId: string) => {
  try {
    const res = await http.delete(`/resumes/work_experience/${experienceId}`);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

// add project
export const addResumeProject = async (project: ProjectInfo) => {
  // Create a copy of the object without the _id field
  const { _id: resumeId, ...projectInfo } = project;
  try {
    const res = await http.post(`/resumes/project/${resumeId}`, projectInfo);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

// edit project
export const editResumeProject = async (project: ProjectInfo) => {
  // Create a copy of the object without the _id field
  const { _id: projectId, ...projectInfo } = project;
  try {
    const res = await http.put(`/resumes/project/${projectId}`, projectInfo);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

// delete project
export const deleteResumeProject = async (projectId: string) => {
  try {
    const res = await http.delete(`/resumes/project/${projectId}`);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

//  add education
export const addResumeEducation = async (education: EducationInfo) => {
  // Create a copy of the object without the _id field
  const { _id: resumeId, ...educationInfo } = education;
  try {
    const res = await http.post(`/resumes/education/${resumeId}`, educationInfo);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

//  edit education
export const editResumeEducation = async (education: EducationInfo) => {
  // Create a copy of the object without the _id field
  const { _id: educationId, ...educationInfo } = education;
  try {
    const res = await http.put(`/resumes/education/${educationId}`, educationInfo);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

//  delete education
export const deleteResumeEducation = async (educationId: string) => {
  try {
    const res = await http.delete(`/resumes/education/${educationId}`);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

// TODO: create a single service for edit and add
//  add coursework
export const addResumeCoursework = async (coursework: CourseworkInfo) => {
  // Create a copy of the object without the _id field
  const { _id: resumeId, ...courseworkInfo } = coursework;
  try {
    const res = await http.post(`/resumes/coursework/${resumeId}`, courseworkInfo);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

//  edit coursework
export const editResumeCoursework = async (coursework: CourseworkInfo) => {
  // Create a copy of the object without the _id field
  const { _id: courseworkId, ...courseworkInfo } = coursework;
  try {
    const res = await http.put(`/resumes/coursework/${courseworkId}`, courseworkInfo);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

//  delete coursework
export const deleteResumeCoursework = async (courseworkId: string) => {
  try {
    const res = await http.delete(`/resumes/coursework/${courseworkId}`);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

// TODO: create a single service for edit and add

//  add patent
export const addResumePatent = async (patent: PatentInfo) => {
  // Create a copy of the object without the _id field
  const { _id: resumeId, ...patentInfo } = patent;
  try {
    const res = await http.post(`/resumes/patents/${resumeId}`, patentInfo);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

//  edit patent
export const editResumePatent = async (patent: PatentInfo) => {
  // Create a copy of the object without the _id field
  const { _id: patentId, ...patentInfo } = patent;
  try {
    const res = await http.put(`/resumes/patents/${patentId}`, patentInfo);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

//  delete patent
export const deleteResumePatent = async (patentId: string) => {
  try {
    const res = await http.delete(`/resumes/patents/${patentId}`);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

// download resume
export const generateResume = async (
  html: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: { filename?: string; margins?: any },
) => {
  try {
    const res = await http.post(
      `/resumes/generate`,
      { html, options },
      {
        responseType: 'blob',
      },
    );

    if (res?.data) {
      return await Promise.resolve(res?.data);
    }
    return await Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};

// delete resume
export const deleteResume = async (resumeId: string) => {
  try {
    const res = await http.delete(`/resumes/${resumeId}`);

    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

// duplicate resume
export const duplicateResume = async (resumeId: string) => {
  try {
    const res = await http.get(`/resumes/duplicate/${resumeId}`);

    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const uploadResumeFile = async (resume: File) => {
  if (!resume) {
    return Promise.reject(new Error('Resume file is required'));
  }

  try {
    const formData = new FormData();
    formData.append('resume', resume);
    const res = await http.post('/resumes/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res?.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

// TODO: create a single service for edit and add
//  edit interests
export const editResumeInterests = async (interests: InterestsInfo) => {
  // Create a copy of the object without the _id field
  const { _id: interestsId, ...interestsInfo } = interests; // TODO: ID should be resumeID
  try {
    const res = await http.put(`/resumes/interest/${interestsId}`, interestsInfo);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

//  add interests
export const addResumeInterests = async (interests: InterestsInfo) => {
  // Create a copy of the object without the _id field
  const { _id: interestsId, ...interestsInfo } = interests;
  try {
    const res = await http.post(`/resumes/interest/${interestsId}`, interestsInfo);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

//  delete interests
export const deleteResumeInterest = async (interestsId: string) => {
  try {
    const res = await http.delete(`/resumes/interest/${interestsId}`);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const reorderWorkExperiences = async (resumeData: {
  resumeId: string;
  sortedWorkExperienceKeys: SortedResumeSectionKey[];
}) => {
  const { resumeId, sortedWorkExperienceKeys } = resumeData;
  try {
    const res = await http.post(
      `/resumes/${resumeId}/update_work_experience_index`,
      sortedWorkExperienceKeys,
    );
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res?.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const reorderProjects = async (resumeData: {
  resumeId: string;
  sortedProjectKeys: SortedResumeSectionKey[];
}) => {
  const { resumeId, sortedProjectKeys } = resumeData;
  try {
    const res = await http.post(`/resumes/${resumeId}/update_project_index`, sortedProjectKeys);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res?.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const reorderEducation = async (resumeData: {
  resumeId: string;
  sortedEducationKeys: SortedResumeSectionKey[];
}) => {
  const { resumeId, sortedEducationKeys } = resumeData;
  try {
    const res = await http.post(`/resumes/${resumeId}/update_education_index`, sortedEducationKeys);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res?.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const reorderResumeSection = async (resumeData: {
  resumeId: string;
  sectionKey: ResumeKey;
  sortedResumeSectionKeys: SortedResumeSectionKey[];
}) => {
  const { resumeId, sectionKey, sortedResumeSectionKeys } = resumeData;
  try {
    const res = await http.post(
      `/resumes/${sectionKey}/${resumeId}/${sectionKey}_index`,
      sortedResumeSectionKeys,
    );
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res?.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const manageReorderResumeSections = async (resumeDate: {
  resumeId: string;
  sections: SectionOrder[];
}) => {
  const { resumeId, sections } = resumeDate;
  try {
    const res = await http.post(`/resumes/${resumeId}/update_section_order`, sections);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res?.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const resumeCompanySearch = async (data: { resumeId: string; query: string }) => {
  const { resumeId, query } = data;
  try {
    const res = await http.get(`/resumes/${resumeId}/company/${query ? `?query=${query}` : ''}`);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res?.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addResumeSection = async (params: {
  resumeId: string;
  sectionKey: string;
  data: ResumeSectionType;
}) => {
  const { resumeId, sectionKey, data } = params;
  try {
    const res = await http.post(`/resumes/${sectionKey}/${resumeId}`, R.omit(['_id'], data));
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res?.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

// TODO: use patch call for section update
export const updateResumeSection = async (params: {
  elementId: string;
  sectionKey: string;
  data: ResumeSectionType; // TODO: use generic
}) => {
  const { elementId, sectionKey, data } = params;
  try {
    const res = await http.put(`/resumes/${sectionKey}/${elementId}`, R.omit(['_id'], data));
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res?.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateResumeSectionData = async (params: {
  resumeId: string;
  data: ResumeSectionType; // TODO: use generic
}) => {
  const { resumeId, data } = params;
  try {
    const res = await http.patch(`/resumes/${resumeId}`, data);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res?.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteResumeSection = async (params: { elementId: string; sectionKey: string }) => {
  const { elementId, sectionKey } = params;
  try {
    const res = await http.delete(`/resumes/${sectionKey}/${elementId}`);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res?.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Fetches the career stages.
 *
 * This API returns an object containing career stages, each stage contains:
 * - `title`: The title of the career stage.
 * - `sectionOrder`: The order of sections within the career stage.
 *
 * @returns {Object<{ title: string, sectionOrder: SectionOrder[] }>}: The career stages.
 */
export const getCareerStages = async (): Promise<CareerStagesResponse> => {
  try {
    const res = await http.get('/resume/career_stage/');
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res?.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Updates the career stage of a resume.
 *
 * @param params - The parameters for updating the career stage of a resume.
 * @param params.resumeId - The ID of the resume.
 * @param params.careerStage - The new career stage.
 * @returns A promise that resolves to the updated resume data.
 * @throws An error if the resume ID or career stage is missing, or if the update fails.
 */
export const updateCareerStage = async (params: {
  resumeId: string;
  careerStage: string;
}): Promise<ResumeData> => {
  const { resumeId, careerStage } = params;

  if (!resumeId || !careerStage) {
    return Promise.reject(new Error('Resume ID and Career Stage are required'));
  }

  try {
    const res = await http.put(`/resume/${resumeId}/career_stage`, { career_stage: careerStage });
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res?.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};
