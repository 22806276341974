import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';

import {
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import FileDropzone from '../../../components/FileDropzone';
import Button from '../../../components/common/Button';
import Card from '../../../components/common/Card';
import Modal from '../../../components/common/Modal/Modal';
import ModalActionsDeprecated from '../../../components/common/Modal/ModalActions_deprecated';
import ModalDetail from '../../../components/common/Modal/ModalDetail';
import type { Resume } from '../../../services/resumes';
import { ResumesKeys, getAllProcessedResumes } from '../../../services/resumes';
import TextWrappedLinkButton from '../../JobScore/ResumeScore/components/TextWrappedLinkButton';

export enum SelectResumeSection {
  SELECT_RESUME = 'select-resume',
  IMPORT_RESUME = 'import-resume',
}

const SelectResume = ({
  onSelected,
  onAICustomizeResumeChange,
  enableAiCustomizeResumeOption = true,
  shouldDisplayAiCustomizeResume = true,
  defaultValue,
}: {
  onSelected: (data: {
    jdId?: string;
    resumeId?: string;
    jDText?: string;
    resumeFile?: File;
  }) => void;
  onAICustomizeResumeChange?: (shouldAiCustomizeResume: boolean) => void;
  enableAiCustomizeResumeOption?: boolean;
  shouldDisplayAiCustomizeResume?: boolean;
  defaultValue?: {
    section: SelectResumeSection;
    value: string | File | undefined;
    shouldAiCustomizeResume?: boolean;
  };
}) => {
  const [selectedSection, setSelectedSection] = useState<SelectResumeSection>(
    defaultValue ? defaultValue.section : SelectResumeSection.SELECT_RESUME,
  );

  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedResume, setSelectedResume] = useState<Resume>();
  const [shouldAiCustomizeResume, setShouldAiCustomizeResume] = useState(
    defaultValue ? defaultValue.shouldAiCustomizeResume : false,
  );

  const { data: resumes, isLoading } = useQuery({
    queryKey: [ResumesKeys.RESUMES, 'processed'],
    queryFn: getAllProcessedResumes,
  });

  useEffect(() => {
    if (
      defaultValue?.section === SelectResumeSection.SELECT_RESUME &&
      typeof defaultValue?.value === 'string'
    ) {
      const resume = resumes?.find((el) => el._id === defaultValue.value);
      setSelectedResume(resume);
    }
  }, [defaultValue?.section, defaultValue?.value, resumes]);

  const handleSelectSelectionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedSection(event.target.value as SelectResumeSection);
  };

  const handleSelectResume = (event: ChangeEvent<HTMLInputElement>) => {
    onSelected({ resumeId: event.target.value });
    const resume = resumes?.find((el) => el._id === event.target.value);
    setSelectedResume(resume);
    setShouldAiCustomizeResume(false);
  };

  const handleSetFile = (_file: File | undefined) => {
    onSelected({ resumeFile: _file });
    setSelectedResume(undefined);
    setShouldAiCustomizeResume(false);
  };

  const handleAiCustomizeCheckChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShouldAiCustomizeResume(event.target.checked);
    onAICustomizeResumeChange?.(event.target.checked);
  };

  return (
    <Stack sx={{ marginTop: 4 }}>
      <FormControl>
        <RadioGroup
          defaultValue={selectedSection}
          name="select-resume-sections-group"
          onChange={handleSelectSelectionChange}
        >
          <FormControlLabel
            value={SelectResumeSection.SELECT_RESUME}
            control={<Radio />}
            label={
              <Typography
                variant="body2"
                sx={{
                  fontWeight:
                    selectedSection === SelectResumeSection.SELECT_RESUME ? '500' : 'inherit',
                }}
              >
                Select a Resume
              </Typography>
            }
          />
          {selectedSection === SelectResumeSection.SELECT_RESUME && (
            // TODO: move to a separate component
            <Stack sx={{ marginBottom: '40px', paddingLeft: '20px' }}>
              <Card
                rootSx={{
                  '& .MuiCardContent-root': {
                    paddingLeft: 0,
                    paddingRight: 0,
                  },
                }}
                sx={{
                  borderRadius: '8px',
                  height: '40vh',
                  overflowY: 'scroll',
                }}
              >
                <FormControl sx={{ width: '100%' }}>
                  <Stack alignItems="center">{isLoading && <CircularProgress />}</Stack>
                  <RadioGroup
                    name="select-resumes-group"
                    onChange={handleSelectResume}
                    defaultValue={
                      typeof defaultValue?.value === 'string' ? defaultValue?.value : undefined
                    }
                  >
                    {Array.isArray(resumes) &&
                      resumes.map(
                        (resume) =>
                          (!resume?.is_ai_customized || shouldDisplayAiCustomizeResume) && (
                            <FormControlLabel
                              key={resume._id}
                              value={resume._id}
                              control={<Radio />}
                              label={
                                <Stack
                                  sx={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: '4px',
                                  }}
                                >
                                  {resume.is_ai_customized && <Chip label="AI-Customized" />}
                                  <Typography variant="body3">
                                    {resume.name || resume.company_customized_for || 'Base'}
                                  </Typography>
                                  <Typography variant="body3" color="text.secondary">
                                    {resume.filename || '-'}
                                  </Typography>
                                </Stack>
                              }
                              sx={{
                                marginLeft: 0,
                                marginRight: 0,
                                borderRadius: 1,
                                padding: '16px 12px',
                                height: '20px',
                                ':hover': {
                                  backgroundColor: '#EBEBEB',
                                },
                                backgroundColor:
                                  selectedResume?._id === resume._id
                                    ? 'primary.light'
                                    : 'transparent',
                              }}
                            />
                          ),
                      )}
                  </RadioGroup>
                </FormControl>
              </Card>
              {enableAiCustomizeResumeOption &&
                selectedResume &&
                !selectedResume?.is_ai_customized && (
                  <Stack sx={{ marginTop: 1 }}>
                    {/* <Checkbox label="AI-Customize this Resume" /> */}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={shouldAiCustomizeResume}
                          onChange={handleAiCustomizeCheckChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label="AI-Customize this Resume before Calculating the Score"
                      sx={{
                        '& .MuiFormControlLabel-label': {
                          fontSize: '14px',
                        },
                      }}
                    />
                  </Stack>
                )}
            </Stack>
          )}
          <FormControlLabel
            value={SelectResumeSection.IMPORT_RESUME}
            control={<Radio />}
            label={<Typography variant="body2">Import New Resume</Typography>}
            sx={{
              marginTop: 1,
            }}
          />
          {selectedSection === SelectResumeSection.IMPORT_RESUME && (
            <Stack paddingLeft={3.5}>
              <FormHelperText sx={{ margin: '0px 0px 8px 4px' }}>
                <TextWrappedLinkButton
                  variant="body3"
                  text={{
                    startingText: 'Importing a new resume creates a base resume. ',
                    linkText: 'What is a base resume?',
                    endingText: '',
                  }}
                  onClick={() => setShowModal(true)}
                />
              </FormHelperText>
              <Modal open={showModal} contentSx={{ width: '500px' }}>
                <>
                  <ModalDetail
                    title="What is a Base Resume?"
                    description="Importing a new resume creates a base resume that you can duplicate, edit, AI customize, and download. Using the base resume, you can easily create many versions of resumes for different job positions. Avoid importing the same resume twice."
                  />
                  <ModalActionsDeprecated
                    actions={
                      <Button color="primary" onClick={() => setShowModal(false)}>
                        <Typography variant="label2">Got it</Typography>
                      </Button>
                    }
                  />
                </>
              </Modal>
              <FileDropzone
                hideLabel
                onFileChange={handleSetFile}
                defaultValue={
                  typeof defaultValue?.value !== 'string' ? defaultValue?.value : undefined
                }
              />
            </Stack>
          )}
        </RadioGroup>
      </FormControl>
    </Stack>
  );
};

export default SelectResume;
