import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $insertNodes,
  type LexicalEditor,
} from 'lexical';
import { isEmpty, isNil } from 'ramda';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { Control, FieldValues } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import uuid from 'react-uuid';

import { $generateNodesFromDOM } from '@lexical/html';
import type { AutocompleteRenderInputParams } from '@mui/material';
import { Autocomplete, Checkbox, Stack, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useQuery } from '@tanstack/react-query';
import { useDebounce } from '@uidotdev/usehooks';

import { careerBreakReasonsList, monthList, yearsList } from '../../../../constants';
import { useSnackbar } from '../../../../contexts/snackbar';
import { useAbortSignal } from '../../../../hooks/useAbortSignal';
import type { ExperienceInfo } from '../../../../interface';
import { EventAction, EventCategory, logEvent } from '../../../../services/analytics';
import { ResumesKeys, resumeCompanySearch } from '../../../../services/resumes';
import type { ComposeFields } from '../../../../services/superEditor';
import {
  autofixWorkExperience,
  composeWorkExperience,
  superChargeWorkExperience,
} from '../../../../services/superEditor';
import type { Draft } from '../../../../stores/SuperEditorDrafts';
import useDraftsStore, { DraftType } from '../../../../stores/SuperEditorDrafts';
import {
  getDraftTooltipText,
  getDraftsIcon,
  getTextFromHtml,
  isHtmlString,
  isNotNilOrEmpty,
} from '../../../../utils';
import { getHtmlStringFromEventStreamText } from '../../../../utils/apiUtils';
import {
  filterMonthList,
  filterYearList,
  getIntegerMonth,
  getStringMonth,
  isValidMonth,
  validateEndYear,
  validateStartYear,
} from '../../../../utils/dateUtils';
import FormActions from '../../../Form/FormActions';
import RichTextEditor from '../../../RichTextEditor/RichTextEditor';
import type { ToolbarExternalControl } from '../../../RichTextEditor/plugins/ToolbarPlugin';
import Dropdown from '../../../common/Dropdown';
import InputLabel from '../../../common/InputLabel';
import LoadingSpinner from '../../../common/LoadingSpinner';
import { ComposeWorkExpModal } from '../ComposeWorkExpModal';
import DraftsManager from '../Drafts/DraftsManager';

interface WorkExperienceEditorProps {
  // Define a prop to accept the callback function
  onUpdate: (workExperience: ExperienceInfo, method: string) => Promise<void>;
  defaultWorkExperience?: ExperienceInfo;
  resumeId?: string;
}

const WorkExperienceEditor = ({
  onUpdate,
  defaultWorkExperience,
  resumeId = '',
}: WorkExperienceEditorProps) => {
  const location = useLocation();
  const { resumeEditorState } = location.state;

  const isPageLoading = resumeEditorState.action === 'edit' && isNil(defaultWorkExperience);
  const [description, setDescription] = useState(defaultWorkExperience?.description || '');
  const method = defaultWorkExperience?._id === '' ? 'add' : 'edit';
  const isCareerBreak = defaultWorkExperience?.is_career_break;
  const CAREER_BREAK_TEXT = 'Career Break';

  const [isComposeModalOpen, setIsComposeModalOpen] = useState(false);
  const [isComposing, setIsComposing] = useState(false);

  const { abortSignal } = useAbortSignal();
  const { showSnackbar } = useSnackbar();

  const [searchQuery, setSearchQuery] = useState(defaultWorkExperience?.company || ''); // used with the company autocomplete

  const richTextEditorRef = useRef<LexicalEditor>();
  const formRef = useRef<HTMLFormElement>(null);

  const {
    drafts,
    maxDrafts,
    isLoading: isDraftsLoading,
    addDraft,
    updateDraft,
    getDraft,
    removeDraft,
  } = useDraftsStore();

  const FormDefaultValues = useMemo(
    () => ({
      _id: defaultWorkExperience?._id || '',
      company: isCareerBreak ? CAREER_BREAK_TEXT : defaultWorkExperience?.company || '',
      job_title: defaultWorkExperience?.job_title || '',
      present: defaultWorkExperience?.present || false,
      location: defaultWorkExperience?.location || '',
      start_month: getStringMonth(defaultWorkExperience?.start_month),
      start_year: defaultWorkExperience?.start_year,
      end_month: getStringMonth(defaultWorkExperience?.end_month),
      end_year: defaultWorkExperience?.end_year,
    }),
    [defaultWorkExperience, isCareerBreak],
  );

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    watch,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: FormDefaultValues,
  });

  const jobTitleValue = watch('job_title');
  const isWorking = watch('present');

  // Rfresh form values on defeaultWorkExperience changes; usually happens on page refresh
  useEffect(() => {
    if (isNotNilOrEmpty(defaultWorkExperience)) {
      reset(FormDefaultValues);
      setDescription(defaultWorkExperience?.description || '');
    }
  }, [FormDefaultValues, defaultWorkExperience, reset, setValue]);

  const onRichTextEditorRef = (ref: LexicalEditor) => {
    richTextEditorRef.current = ref;
  };

  const onSubmit = (data: FieldValues) => {
    onUpdate(
      {
        _id: data._id,
        present: data.present,
        location: data.location.trim(),
        company: data.company.trim(),
        job_title: data.job_title.trim(),
        start_year: data.start_year === '' ? null : data.start_year,
        end_year: data.end_year === '' ? null : data.end_year,
        start_month:
          isValidMonth(data.start_month) || isEmpty(data.start_month)
            ? getIntegerMonth(data.start_month)
            : (defaultWorkExperience?.start_month as number),
        end_month:
          isValidMonth(data.end_month) || isEmpty(data.end_month)
            ? getIntegerMonth(data.end_month)
            : (defaultWorkExperience?.end_month as number),
        description,
      },
      method,
    );
  };

  // TODO: check required fields validation, replace with react-hook-form validations
  const onValidateStartYear = (startYear: number | null | undefined) => {
    clearErrors('end_year');
    if (isCareerBreak && isNil(startYear)) {
      return 'Please enter a start year';
    }
    return validateStartYear(
      getValues('start_month'),
      startYear,
      getValues('end_month'),
      getValues('end_year'),
      getValues('present'),
    );
  };

  const onValidateEndYear = (endYear: number | null | undefined) => {
    if (errors?.start_year) {
      return true;
    }
    if (isCareerBreak && isNil(endYear)) {
      return 'Please enter an end year';
    }
    return validateEndYear(getValues('start_year'), getValues('end_month'), endYear);
  };

  const onFilterStartYearList = (startYear: number) =>
    filterYearList(
      getValues('start_month'),
      startYear,
      getValues('end_month'),
      getValues('end_year'),
      getValues('present'),
    );

  const onFilterEndYearList = (endYear: number) =>
    filterYearList(
      getValues('start_month'),
      getValues('start_year'),
      getValues('end_month'),
      endYear,
      getValues('present'),
    );

  const onFilterStartMonthList = (startMonth: string) =>
    filterMonthList(
      startMonth,
      getValues('start_year'),
      getValues('end_month'),
      getValues('end_year'),
      getValues('present'),
    );

  const onFilterEndMonthList = (endMonth: string) =>
    filterMonthList(
      getValues('start_month'),
      getValues('start_year'),
      endMonth,
      getValues('end_year'),
      getValues('present'),
    );

  const onRichTextEditorChange = (text: string) => {
    setDescription(text);
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length);
    window.scrollTo(0, 0);
  };

  const [isCompanyDropdownOpen, setIsCompanyDropdownOpen] = useState(false);
  const debouncedSearchQuery = useDebounce(searchQuery, 250);

  const { data: companies, isLoading } = useQuery({
    enabled: isCompanyDropdownOpen && Boolean(resumeId) && !isCareerBreak,
    queryKey: [ResumesKeys.RESUMES, resumeId, ResumesKeys.COMPANIES, debouncedSearchQuery],
    queryFn: () =>
      resumeCompanySearch({
        resumeId,
        query: debouncedSearchQuery,
      }),
  });

  const companyAutoCompleteProps = {
    ...register('company', {
      required: 'Enter a company name',
      validate: {
        notEmpty: (value: string) =>
          !isEmpty(value.trim()) || 'Please provide a valid company name',
      },
    }),
  };

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      placeholder="Enter a company name"
      sx={{
        '& .MuiOutlinedInput-root': {
          border: '1px solid',
          borderColor: 'border.light',
          borderRadius: '8px',
          padding: 0,
          '& .MuiInputBase-input': {
            padding: '10px',
          },
          '& fieldset': {
            border: 0,
          },
        },
      }}
      variant="outlined"
      error={!!errors.company}
      autoFocus
      onFocus={handleFocus}
      fullWidth
    />
  );

  const onAppendDraft = (draft: Draft) => {
    // TODO: think about moving these to the rich text editor, might be a better idea to explore editor Commands
    if (richTextEditorRef.current) {
      const { text } = draft;
      richTextEditorRef.current.update(() => {
        if (!text || !richTextEditorRef.current) {
          return;
        }

        const root = $getRoot();

        // check if draft.text is HTML or plain text
        if (isHtmlString(text)) {
          // de-serialize html to editor state / nodes
          const parser = new DOMParser();
          const dom = parser.parseFromString(text, 'text/html');
          const nodes = $generateNodesFromDOM(richTextEditorRef.current, dom);
          root.append($createParagraphNode()); // create a new paragraph for empty text
          root.append(...nodes);
        } else {
          const paragraph = $createParagraphNode();
          const element = $createTextNode(text);
          paragraph.append(element);
          root.append(paragraph);
        }

        root.selectEnd();
      });
    }
  };

  const onReplaceDraft = (draft: Draft) => {
    // TODO: think about moving these to the rich text editor, might be a better idea to explore editor Commands
    if (richTextEditorRef.current) {
      const { text } = draft;

      richTextEditorRef.current.update(() => {
        if (!text || !richTextEditorRef.current) {
          return;
        }

        const root = $getRoot();
        root.clear();

        // check if draft.text is HTML or plain text
        if (isHtmlString(text)) {
          // de-serialize html to editor state / nodes
          const parser = new DOMParser();
          const dom = parser.parseFromString(text, 'text/html');
          const nodes = $generateNodesFromDOM(richTextEditorRef.current, dom);
          $insertNodes(nodes);
        } else {
          const paragraph = $createParagraphNode();
          const element = $createTextNode(text);
          paragraph.append(element);
          root.append(paragraph);
        }

        root.selectEnd();
      });
    }
  };

  const onAutofixClick = useCallback(() => {
    const id = uuid();

    if (!description) {
      return;
    }

    // add empty draft
    addDraft({
      id,
      text: '',
      type: DraftType.AUTOFIX,
      status: 'loading',
    });

    autofixWorkExperience({
      signal: abortSignal,
      description,
      onProgress: (data) => {
        // add slight delay
        setTimeout(() => {
          addDraft({
            id,
            text: data,
            type: DraftType.AUTOFIX,
            status: 'loading',
          });
        }, 500);
      },
      onEnd: (data) => {
        // adding an artificial delay, because for small texts its too fast
        setTimeout(() => {
          logEvent(EventCategory.USER_INTERACTION, EventAction.CLICK, 'Work Experience Autofixed');
          updateDraft(id, {
            id,
            text: data,
            type: DraftType.AUTOFIX,
            status: 'success',
          });
          formRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }, 1000);
      },
    }).catch((e: Error) => {
      if (e.name !== 'AbortError') {
        showSnackbar(
          'error',
          e?.message || 'An error occurred while autofixing the Work Experience.',
        );
      }
      const draft = getDraft(id);
      if (draft?.text === '') {
        removeDraft(id);
      } else {
        updateDraft(id, {
          type: DraftType.AUTOFIX,
          status: 'error',
        });
      }
    });
  }, [description, addDraft, abortSignal, updateDraft, getDraft, showSnackbar, removeDraft]);

  const onSuperChargeClick = useCallback(() => {
    const id = uuid();

    if (!description) {
      return;
    }

    // process description HTML string and add each p tag and li tag to a separate bullet point string in the bulletPointString array
    const bulletPoints: string[] = [];
    const descriptionHTML = new DOMParser().parseFromString(description, 'text/html');
    const pTags = descriptionHTML.querySelectorAll('p');
    const liTags = descriptionHTML.querySelectorAll('li');

    pTags.forEach((pTag) => {
      if (pTag.innerText !== '') {
        bulletPoints.push(pTag.innerText);
      }
    });

    liTags.forEach((liTag) => {
      if (liTag.innerText !== '') {
        bulletPoints.push(liTag.innerText);
      }
    });

    // add empty draft, to show loading - as API response is too slow, 5+ seconds
    addDraft({
      id,
      text: '',
      type: DraftType.SUPERCHARGE,
      status: 'loading',
    });

    superChargeWorkExperience({
      signal: abortSignal,
      bulletPoints,
      title: getValues('job_title'),
      onProgress: (data) => {
        // add slight delay
        setTimeout(() => {
          addDraft({
            id,
            text: getHtmlStringFromEventStreamText(data),
            type: DraftType.SUPERCHARGE,
            status: 'loading',
          });
        }, 500);
      },
      onEnd: (data) => {
        // adding an artificial delay, because for small texts its too fast
        setTimeout(() => {
          logEvent(
            EventCategory.USER_INTERACTION,
            EventAction.CLICK,
            'Work Experience Supercharged',
          );
          updateDraft(id, {
            id,
            text: getHtmlStringFromEventStreamText(data),
            type: DraftType.SUPERCHARGE,
            status: 'success',
          });
          formRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }, 1000);
      },
    }).catch((e: Error) => {
      if (e.name !== 'AbortError') {
        showSnackbar(
          'error',
          e?.message || 'An error occurred while supercharging the Work Experience',
        );
      }
      const draft = getDraft(id);
      if (draft?.text === '') {
        removeDraft(id);
      } else {
        updateDraft(id, {
          type: DraftType.SUPERCHARGE,
          status: 'error',
        });
      }
    });
  }, [
    description,
    addDraft,
    abortSignal,
    getValues,
    updateDraft,
    getDraft,
    showSnackbar,
    removeDraft,
  ]);

  const onCompose = () => {
    setIsComposeModalOpen(true);
  };

  const onComposeSubmit = (fields: ComposeFields) => {
    setIsComposeModalOpen(false);
    setIsComposing(true);

    composeWorkExperience({
      fields,
      onProgress: (text) => {
        if (!text) {
          return;
        }

        if (richTextEditorRef.current) {
          const html = getHtmlStringFromEventStreamText(text);
          // replace the editor content with the text
          richTextEditorRef.current.update(() => {
            const root = $getRoot();
            root.clear();
            // check if draft.text is HTML or plain text
            if (isHtmlString(html) && richTextEditorRef.current) {
              // de-serialize html to editor state / nodes
              const parser = new DOMParser();
              const dom = parser.parseFromString(html, 'text/html');
              const nodes = $generateNodesFromDOM(richTextEditorRef.current, dom);
              $insertNodes(nodes);
            } else {
              const paragraph = $createParagraphNode();
              const element = $createTextNode(html);
              paragraph.append(element);
              root.append(paragraph);
            }
            root.selectEnd();
          });
        }
      },
      onEnd: (text) => {
        if (!text) {
          setIsComposing(false);
          return;
        }

        logEvent(EventCategory.USER_INTERACTION, EventAction.CLICK, 'Work Experience Composed');

        if (richTextEditorRef.current) {
          const html = getHtmlStringFromEventStreamText(text);
          // replace the editor content with the text
          richTextEditorRef.current.update(() => {
            const root = $getRoot();
            root.clear();
            if (isHtmlString(html) && richTextEditorRef.current) {
              // de-serialize html to editor state / nodes
              const parser = new DOMParser();
              const dom = parser.parseFromString(html, 'text/html');
              const nodes = $generateNodesFromDOM(richTextEditorRef.current, dom);
              $insertNodes(nodes);
            } else {
              const paragraph = $createParagraphNode();
              const element = $createTextNode(html);
              paragraph.append(element);
              root.append(paragraph);
            }
            root.selectEnd();
          });
        }
        setIsComposing(false);
      },
    }).catch((e: Error) => {
      if (e.name !== 'AbortError') {
        showSnackbar(
          'error',
          e?.message || 'An error occurred while composing the Work Experience',
        );
      }
      setIsComposing(false);
    });
  };

  const toolbarExternalControls = useMemo<ToolbarExternalControl[]>(() => {
    if (isCareerBreak) {
      return [];
    }

    const hasMaxDrafts = drafts.length === maxDrafts;

    // enable autofix and supercharge buttons if description has more than 10 words
    const shouldDisable =
      isDraftsLoading || hasMaxDrafts || getTextFromHtml(description).trim().split(' ').length < 10;

    const jobTitleMeetsLimit = jobTitleValue && jobTitleValue.length >= 3;
    const shouldDisableSuperCharge = shouldDisable || !jobTitleMeetsLimit;

    return [
      {
        title: 'Autofix',
        icon: getDraftsIcon(DraftType.AUTOFIX),
        onClick: onAutofixClick,
        tooltipText: getDraftTooltipText(
          DraftType.AUTOFIX,
          'Work Experience',
          shouldDisable,
          hasMaxDrafts,
          maxDrafts,
        ),
        disabled: shouldDisable,
      },
      {
        title: 'Supercharge',
        icon: getDraftsIcon(DraftType.SUPERCHARGE),
        onClick: onSuperChargeClick,
        tooltipText: getDraftTooltipText(
          DraftType.SUPERCHARGE,
          'Work Experience',
          shouldDisableSuperCharge,
          hasMaxDrafts,
          maxDrafts,
          !jobTitleMeetsLimit ? 'Supercharge requires a job title' : undefined,
        ),
        disabled: shouldDisableSuperCharge,
      },
    ];
  }, [
    description,
    drafts.length,
    isCareerBreak,
    isDraftsLoading,
    jobTitleValue,
    maxDrafts,
    onAutofixClick,
    onSuperChargeClick,
  ]);

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
      {isPageLoading ? (
        <LoadingSpinner />
      ) : (
        <Stack gap={3}>
          {!isCareerBreak && (
            <Stack gap={1}>
              <InputLabel htmlFor="company" label="Company Name" required />
              <Autocomplete
                id="company-dropdown"
                freeSolo
                open={isCompanyDropdownOpen}
                onOpen={() => setIsCompanyDropdownOpen(true)}
                onClose={() => setIsCompanyDropdownOpen(false)}
                options={companies || []}
                loading={resumeId !== '' && isLoading && isCompanyDropdownOpen}
                {...companyAutoCompleteProps}
                onInputChange={(event, newInputValue) => {
                  setValue('company', newInputValue);
                  setSearchQuery(newInputValue);
                }}
                defaultValue={defaultWorkExperience?.company || ''}
                renderInput={renderInput}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Typography variant="body2">{option}</Typography>
                  </li>
                )}
              />
              {!!errors?.company && (
                <Typography variant="assistive" color="error">
                  {errors?.company?.message?.toString()}
                </Typography>
              )}
            </Stack>
          )}

          <Stack gap={1}>
            <InputLabel
              htmlFor="job_title"
              label={isCareerBreak ? 'Career Break Reason' : 'Job Title'}
              required={isCareerBreak}
            />
            {isCareerBreak ? (
              <Dropdown
                {...register('job_title', {
                  required: 'Career break reason is required',
                  validate: {
                    notEmpty: (value: string) =>
                      !isEmpty(value.trim()) || 'Please provide a valid career break reason',
                  },
                })}
                items={careerBreakReasonsList}
                placeholder="Select or type your own reason"
                defaultValue={defaultWorkExperience?.job_title}
                control={control as unknown as Control<FieldValues>}
                autoFocus
                onFocus={handleFocus}
                freeSolo
              />
            ) : (
              <TextField
                id="job_title"
                placeholder="Enter Job Title"
                {...register('job_title')}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: '1px solid',
                      borderColor: 'border.light',
                      borderRadius: '8px',
                      padding: '10px',
                    },
                  },
                }}
                variant="outlined"
                fullWidth
              />
            )}
          </Stack>

          {!isCareerBreak && (
            <Stack gap={1}>
              <InputLabel htmlFor="location" label="Location" />
              <TextField
                id="location"
                placeholder="Enter Location"
                {...register('location')}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: '1px solid',
                      borderColor: 'border.light',
                      borderRadius: '8px',
                      padding: '10px',
                    },
                  },
                }}
                inputProps={{
                  maxLength: 32,
                }}
                variant="outlined"
                fullWidth
              />
            </Stack>
          )}

          <Grid2 alignItems="center" spacing={1} container>
            <Grid2>
              <Checkbox
                checked={isWorking}
                {...register('present')}
                color="primary"
                sx={{ padding: 0 }}
              />
            </Grid2>
            <Grid2>
              <Typography variant="body3">
                {isCareerBreak ? 'I’m currently on this career break' : 'I currently work here'}
              </Typography>
            </Grid2>
          </Grid2>
          <Stack gap={1}>
            <InputLabel label="From" required={isCareerBreak} />
            <Grid2 justifyContent="space-between" spacing={1} container>
              <Grid2 xs={6}>
                <Dropdown
                  {...register('start_month')}
                  items={monthList}
                  placeholder="Select Start Month"
                  defaultValue={getStringMonth(defaultWorkExperience?.start_month)}
                  control={control as unknown as Control<FieldValues>}
                  disableOptionfilter={onFilterStartMonthList}
                />
              </Grid2>
              <Grid2 xs={6}>
                <Dropdown
                  {...register('start_year', { validate: onValidateStartYear })}
                  items={yearsList}
                  placeholder="Select Start Year"
                  defaultValue={defaultWorkExperience?.start_year}
                  control={control as unknown as Control<FieldValues>}
                  filterOptions={(year) => !onFilterStartYearList(year as number)}
                />
              </Grid2>
            </Grid2>
          </Stack>
          {!isWorking && (
            <Stack gap={1}>
              <InputLabel label="To" required={isCareerBreak} />
              <Grid2 justifyContent="space-between" spacing={1} container>
                <Grid2 xs={6}>
                  <Dropdown
                    {...register('end_month')}
                    items={monthList}
                    placeholder="Select End Month"
                    defaultValue={getStringMonth(defaultWorkExperience?.end_month)}
                    control={control as unknown as Control<FieldValues>}
                    disableOptionfilter={onFilterEndMonthList}
                  />
                </Grid2>
                <Grid2 xs={6}>
                  <Dropdown
                    {...register('end_year', { validate: onValidateEndYear })}
                    items={yearsList}
                    placeholder="Select End Year"
                    defaultValue={defaultWorkExperience?.end_year}
                    control={control as unknown as Control<FieldValues>}
                    filterOptions={(year) => !onFilterEndYearList(year as number)}
                  />
                </Grid2>
              </Grid2>
            </Stack>
          )}
          <Stack gap={1}>
            <Stack gap={0.5}>
              <InputLabel label="Description" />
              {!isCareerBreak && (
                <Typography variant="body3">
                  Describe your achievements, key tasks, responsibilities, and impacts on the team.
                </Typography>
              )}
            </Stack>

            {/* Rich text editor */}
            <RichTextEditor
              placeholder={isCareerBreak ? '' : 'Enter Description'}
              html={description}
              disabled={isDraftsLoading}
              toolbarExternalControls={toolbarExternalControls}
              onEditorRefChange={onRichTextEditorRef}
              onChange={onRichTextEditorChange}
              onCompose={!isCareerBreak ? onCompose : undefined}
              isComposing={isComposing}
            />
          </Stack>

          <DraftsManager
            sectionTitle="Work Experience"
            onAppendDraft={onAppendDraft}
            onReplaceDraft={onReplaceDraft}
          />

          <FormActions sx={{ marginTop: '24px' }} />
          <ComposeWorkExpModal
            open={isComposeModalOpen}
            onClose={() => setIsComposeModalOpen(false)}
            onSubmit={onComposeSubmit}
          />
        </Stack>
      )}
    </form>
  );
};

export default WorkExperienceEditor;
