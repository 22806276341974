import { useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { InputLabel, Link, Stack, TextField, Typography } from '@mui/material';

import Button from '../../components/common/Button';
import Card from '../../components/common/Card';
import Logo from '../../components/common/Logo';
import { useAuth } from '../../contexts/auth';
import RoutePaths from '../../routes/RoutePaths';

type SignUpFormValues = {
  email: string;
  name: string;
};

const SignUpPage = () => {
  const location = useLocation();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors },
  } = useForm<SignUpFormValues>();
  const { signup } = useAuth();
  const navigate = useNavigate();

  const nameProps = register('name', {
    required: 'Please enter the name',
    validate: (value) => value.trim() !== '' || 'Whitespace-only input is not allowed',
    maxLength: {
      value: 32,
      message: 'Name should not exceed 32 characters',
    },
    pattern: {
      value: /^[A-Za-z. '-]+$/,
      message: "Use only upper case(A-Z), lower case (a-z), and some special characters (.'- )",
    },
  });

  const emailProps = register('email', {
    required: 'Please enter the email',
    pattern: {
      value: /\S+@\S+\.\S+/,
      message: 'Please enter correct email address',
    },
  });

  const handleSignUp: SubmitHandler<SignUpFormValues> = async ({ email, name }) => {
    setLoading(true);
    try {
      await signup(email, name);
      setLoading(false);
      setError('');
      navigate(RoutePaths.VERIFICATION, { state: { path: RoutePaths.SIGN_UP, email, name } });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      setError(err?.response?.data?.errors);
      setLoading(false);
    }
  };

  const handleSignIn = (email: string) => {
    navigate(RoutePaths.SIGN_IN, { state: { email } });
  };

  return (
    <Stack
      sx={{
        alignItems: 'center',
        gap: '24px',
        height: '90vh',
        maxWidth: '550px',
        margin: 'auto',
        marginTop: 1,
      }}
    >
      <Logo width={{ xs: '180px', md: '300px' }} height="auto" />
      <form onSubmit={handleSubmit(handleSignUp)}>
        <Card
          sx={{
            width: '80vw',
            maxWidth: '500px',
            padding: { xs: 1, md: 2 },
            borderColor: 'border.light',
            borderRadius: '12px',
          }}
        >
          {/* card content */}
          <Stack gap={6} sx={{ alignItems: 'center', width: '100%' }}>
            <Stack gap={3} sx={{ width: '100%' }}>
              <Typography variant="h2" sx={{ textAlign: 'center' }}>
                Create Account
              </Typography>
              <Stack gap={1} sx={{ width: '100%' }}>
                <InputLabel
                  htmlFor="name"
                  sx={{
                    color: 'error.main',
                    '& .MuiFormLabel-asterisk': { paddingLeft: 0.5, fontSize: '12px' },
                  }}
                  required
                >
                  <Typography variant="label2" color="text.primary">
                    Name
                  </Typography>
                </InputLabel>
                <TextField
                  defaultValue=""
                  placeholder="Kooper Dori"
                  {...nameProps}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      fieldset: {
                        borderColor: 'border.light',
                        borderRadius: '8px',
                      },
                    },
                    width: '100%',
                  }}
                  error={!!errors.name}
                />
                {errors.name?.message && (
                  <Typography variant="assistive" color="error">
                    {errors.name?.message as string}
                  </Typography>
                )}
              </Stack>
              <Stack gap={1}>
                <InputLabel
                  htmlFor="email"
                  sx={{
                    color: 'error.main',
                    '& .MuiFormLabel-asterisk': { paddingLeft: 0.5, fontSize: '12px' },
                  }}
                  required
                >
                  <Typography variant="label2" color="text.primary">
                    Email
                  </Typography>
                </InputLabel>
                {/* TODO: Update textField component to handle react hook form onChange */}
                <TextField
                  defaultValue={location.state?.email || ''}
                  placeholder="dori@example.com"
                  {...emailProps}
                  // TODO: move to styled-components or a better css implementation
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      fieldset: {
                        borderColor: 'border.light',
                        borderRadius: '8px',
                      },
                    },
                    width: '100%',
                  }}
                  error={!!errors.email || !!error}
                />
                {(errors.email?.message || error) && (
                  <Typography variant="assistive" color="error">
                    {(errors.email?.message as string) || error}
                  </Typography>
                )}
              </Stack>
            </Stack>
            <Button type="submit" loading={loading} sx={{ width: '80px' }}>
              <Typography variant="label2"> Next </Typography>
            </Button>
          </Stack>
        </Card>
      </form>
      <Typography variant="body3" sx={{ display: 'flex', alignItems: 'center' }}>
        Already have an account?
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          component="button"
          underline="hover"
          sx={{ paddingLeft: 0.5, color: 'primary.main' }}
          onClick={() => handleSignIn(getValues('email'))}
        >
          Sign In
        </Link>
      </Typography>
    </Stack>
  );
};

export default SignUpPage;
