import { useMemo } from 'react';

import type { ContactInfo, HeroInfo } from '../../../interface';
import { ResumeKey } from '../../../interface';
import { addSeparator, isNotNilOrEmpty } from '../../../utils';
import styles from '../styles';
import type { SectionContentProps } from './SectionContent';
import SectionContent from './SectionContent';

interface TemplateHeaderProps {
  isSectionEnabled: (key: ResumeKey) => boolean;
  contact: ContactInfo;
  heroMessage: HeroInfo;
  sectionContentProps?: SectionContentProps;
}

const TemplateHeader = ({
  isSectionEnabled,
  contact,
  heroMessage,
  sectionContentProps,
}: TemplateHeaderProps) => {
  const contactInfo = useMemo(
    () => [
      addSeparator(contact.city, contact.state, ', '),
      contact.phone,
      contact.email,
      contact.linkedin,
    ],
    [contact],
  );

  const memoizedHeading = useMemo(() => {
    if (sectionContentProps?.heading === '') {
      return undefined;
    }
    return sectionContentProps?.heading || isSectionEnabled(ResumeKey.CONTACT)
      ? `${contact.first_name} ${contact.last_name}`
      : undefined;
  }, [contact.first_name, contact.last_name, isSectionEnabled, sectionContentProps?.heading]);

  const memoizedSubHeading = useMemo(() => {
    if (sectionContentProps?.subHeading === '') {
      return undefined;
    }
    return sectionContentProps?.subHeading || isSectionEnabled(ResumeKey.HERO)
      ? heroMessage.message
      : undefined;
  }, [heroMessage.message, isSectionEnabled, sectionContentProps?.subHeading]);

  const memoizedContent = useMemo(() => {
    if (sectionContentProps?.content === '') {
      return undefined;
    }
    return sectionContentProps?.content || isSectionEnabled(ResumeKey.CONTACT)
      ? contactInfo.filter(isNotNilOrEmpty).join(' | ')
      : undefined;
  }, [contactInfo, isSectionEnabled, sectionContentProps?.content]);

  return (
    <div className="header-info">
      <SectionContent
        style={sectionContentProps?.style}
        heading={memoizedHeading}
        subHeading={memoizedSubHeading}
        content={memoizedContent}
        headingStyle={{
          ...styles.h1,
          ...styles.paddingBottom2,
          ...sectionContentProps?.headingStyle,
        }}
        subHeadingStyle={{ ...styles.h2, ...sectionContentProps?.subHeadingStyle }}
        contentStyle={{ ...styles.body2, ...sectionContentProps?.contentStyle }}
      />
    </div>
  );
};

export default TemplateHeader;
