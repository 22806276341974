import { useLayoutEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import type { AlertColor } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import AddMissingInfoButton from '../../components/common/AddMissingInfoButton';
import Button from '../../components/common/Button';
import Icon from '../../components/common/Icon';
import { useSnackbar } from '../../contexts/snackbar';
import useResponsiveDevice from '../../hooks/useResponsiveDevice';
import { ResponseStatus } from '../../interface';
import RoutePaths from '../../routes/RoutePaths';
import { EventAction, EventCategory, logEvent } from '../../services/analytics';
import { ResumesKeys, getAllResumes } from '../../services/resumes';
import { Storage, StorageKeys } from '../../services/storage';
import { useAppBarStore } from '../../stores/AppBarStore';
import { useLastVisitedTopPageStore } from '../../stores/LastVisitedTopPageStore';
import { getListingSnackbar } from '../../utils';
import ResumesList from './components/ResumesList';
import ResumesTable from './components/ResumesTable';

const snackbarMessage = {
  missing: 'Job X-Ray is missing some required information',
  in_progress: 'New Resume is being created',
  error: 'New Resume could not be imported',
  success: 'Resume created successfully',
};

const Resumes = () => {
  const navigate = useNavigate();
  const { showSnackbar, hideSnackbar } = useSnackbar();
  const { setLastVisitedTopPage } = useLastVisitedTopPageStore();
  const { isMobile, isTablet } = useResponsiveDevice();
  const { setAppBar, resetToDesktopAppBar, setInitialState } = useAppBarStore();
  const [isNavigatedFromResumeProgressPage, setIsNavigatedFromResumeProgressPage] = useState(false);

  const ImportResumeButton = useMemo(
    () => (
      <Button
        variant="text"
        sx={{ padding: 1.5, minWidth: 'max-content' }}
        onClick={() => navigate(RoutePaths.CREATE_RESUME)}
      >
        <Icon className="fi fi-rr-file-upload" fontSize="20px" />
      </Button>
    ),
    [navigate],
  );

  const resumesQueryHandler = async () => {
    const res = await getAllResumes();

    const listingSnackbar = getListingSnackbar(
      res?.status?.status,
      RoutePaths.RESUMES,
      isNavigatedFromResumeProgressPage,
      setIsNavigatedFromResumeProgressPage,
      snackbarMessage,
    );

    if (listingSnackbar) {
      if (listingSnackbar?.showControl) {
        const pendingResumeId = res?.result.find(
          (resume) => resume.status === ResponseStatus.PENDING,
        )?._id;
        const missingInfoButton = (
          <AddMissingInfoButton
            onClick={() => {
              hideSnackbar();
              navigate(RoutePaths.AI_CUSTOMIZED.replace(':id', pendingResumeId || ''), {
                state: { aiCustomizeId: pendingResumeId },
              });
            }}
            sx={{ marginLeft: { xs: 0, md: 2.5 }, color: 'common.white' }}
            typographyVariant="label2"
            hideIcon
          />
        );
        showSnackbar(
          listingSnackbar.severity as AlertColor,
          listingSnackbar.message,
          missingInfoButton,
        );
      } else {
        logEvent(EventCategory.FORM_SUBMISSION, EventAction.SUBMIT, listingSnackbar.message);
        showSnackbar(listingSnackbar.severity as AlertColor, listingSnackbar.message);
      }
    }

    return res;
  };

  const { data: resumesQueryResponse, isLoading } = useQuery({
    queryKey: [ResumesKeys.RESUMES],
    queryFn: resumesQueryHandler,
    refetchInterval: (data) => {
      // if resume is in progress, refetch every 5 seconds
      if (data?.status?.status === ResponseStatus.IN_PROGRESS) {
        return 5000;
      }
      return false;
    },
  });

  // resume data from api response
  const resumes = resumesQueryResponse?.result;

  useLayoutEffect(() => {
    setLastVisitedTopPage(RoutePaths.RESUMES);
    setIsNavigatedFromResumeProgressPage(
      Storage.get(StorageKeys.CONTINUE_TO_JOQBQUEST_URL) === RoutePaths.RESUMES,
    );
  }, [setLastVisitedTopPage]);

  useLayoutEffect(() => {
    if (isMobile || isTablet) {
      setAppBar('Resumes', resumes && resumes?.length > 0 ? ImportResumeButton : null);
    } else {
      resetToDesktopAppBar();
    }
    return () => {
      setInitialState(isMobile);
    };
  }, [
    ImportResumeButton,
    isMobile,
    isTablet,
    resetToDesktopAppBar,
    resumes,
    setAppBar,
    setInitialState,
  ]);

  const onClick = (id: string, status: ResponseStatus) => {
    if (id) {
      if (status === ResponseStatus.DONE) {
        navigate(`${RoutePaths.RESUMES}/${id}`);
      } else if (status === ResponseStatus.PENDING) {
        navigate(RoutePaths.AI_CUSTOMIZED.replace(':id', id), {
          state: { aiCustomizeId: id },
        });
      }
    }
  };

  return isMobile || isTablet ? (
    <ResumesList resumes={resumes} isLoading={isLoading} onClick={onClick} />
  ) : (
    <ResumesTable resumes={resumes} isLoading={isLoading} onClick={onClick} />
  );
};

export default Resumes;
